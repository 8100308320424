import React from 'react'
import PlusIcon from '../../../../../../assets/svg/3d-maker/plus.svg'
import MinusIcon from '../../../../../../assets/svg/3d-maker/minus.svg'
import Slider from '../../../../../Common/Slider/Slider'
import * as styles from './zoom.module.scss'
import {changeLayerZoom} from '../../../../../../slices/three-slice'
import { useDispatch, useSelector, useStore } from 'react-redux'
import {store} from '../../../../../../../configureStore'

const LayerZoom = () => {
    const dispatch = useDispatch()
    let value = useSelector(state => state.ThreeSlice.present.layerZoom)

    return(
        <div className={styles.container}>
            <div className={styles.svgDiv}>
                <MinusIcon />
            </div>
            <Slider value={value} min={0.05} max={1} step={0.0001} onChange={(value) => dispatch(changeLayerZoom(value))} number={false} />
            <div className={styles.svgDiv}>
                <PlusIcon />
            </div>
        </div>
    )
}

export default LayerZoom;