/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useMemo } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(
    "/3DModels/Laptops/new-macbook/scene.gltf"
  );

  const colorMaterials = useMemo(() => {
    return ['keyboard_top_panel01', 'monitor_back_panel01', 'keyboard_bottom_panel01'].reduce((acc, key) => {
        acc[key] = nodes[key].material.clone()
        return acc
    }, {})
  },[nodes])

  const display = useMemo(() => materials.screen.clone(), [materials]);

  useEffect(() => {
    if (group.current) {
      props.init({
        ref: group.current,
        colorMaterials: Object.values(colorMaterials),
        display,
      });
    }
  }, []);

  return (
    <group ref={group} {...props}>
      <group scale={[10, 10, 10]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.monitor_screen_border01.geometry}
          material={nodes.monitor_screen_border01.material}
          position={[0, 0.00224, -0.10482]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.monitor_rotation_element01.geometry}
          material={nodes.monitor_rotation_element01.material}
          position={[0, -0.10445, -0.10001]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.monitor_monitor_back_panel_part_01.geometry}
          material={materials.chrome_logo}
          position={[0.00022, 0.00068, -0.10856]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.monitor_front_panel_text01.geometry}
          material={nodes.monitor_front_panel_text01.material}
          position={[0.00029, -0.09549, -0.10432]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.monitor_front_panel01.geometry}
          material={nodes.monitor_front_panel01.material}
          position={[0, -0.00062, -0.1046]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.monitor_front_glass01.geometry}
          material={materials.back_black}
          position={[0, 0.00584, -0.10473]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.monitor_front_camera_part_01.geometry}
          material={nodes.monitor_front_camera_part_01.material}
          position={[0, 0.09735, -0.10517]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.monitor_front_camera_lens01.geometry}
          material={nodes.monitor_front_camera_lens01.material}
          position={[0, 0.09735, -0.10497]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.monitor_front_camera_border_part_01.geometry}
          material={materials.mat_gray_dark}
          position={[0, 0.09735, -0.105]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.monitor_front_camera_border01.geometry}
          material={nodes.monitor_front_camera_border01.material}
          position={[0, 0.09735, -0.1049]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.monitor_back_panel01.geometry}
          material={colorMaterials.monitor_back_panel01}
          position={[0, -0.00062, -0.10219]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_buttons01.geometry}
          material={nodes.keyboard_top_panel_buttons01.material}
          position={[0.00012, -0.10228, -0.0356]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel01.geometry}
          material={colorMaterials.keyboard_top_panel01}
          position={[0, -0.10419, 0.00257]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_bottom_panel_part_01.geometry}
          material={nodes.keyboard_bottom_panel_part_01.material}
          position={[0, -0.1104, 0.00197]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_buttons_part_05.geometry}
          material={nodes.keyboard_top_panel_buttons_part_05.material}
          position={[0, -0.10371, -0.03563]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_buttons_part_03.geometry}
          material={nodes.keyboard_top_panel_buttons_part_03.material}
          position={[0.13238, -0.10026, -0.08183]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_buttons_part_02.geometry}
          material={nodes.keyboard_top_panel_buttons_part_02.material}
          position={[-0.13326, -0.10171, -0.0343]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_buttons_part_01.geometry}
          material={materials.glass}
          position={[-0.13316, -0.1015, -0.03425]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_buttons_text01.geometry}
          material={nodes.keyboard_top_panel_buttons_text01.material}
          position={[-0.00006, -0.1014, -0.03605]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_dynamic01.geometry}
          material={nodes.keyboard_top_panel_dynamic01.material}
          position={[0, -0.1017, -0.03543]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports_part_04.geometry}
          material={nodes.keyboard_top_panel_ports_part_04.material}
          position={[-0.00009, -0.10402, -0.07459]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports01.geometry}
          material={nodes.keyboard_top_panel_ports01.material}
          position={[0.00058, -0.10419, -0.07459]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports_part_03.geometry}
          material={materials.gold}
          position={[0.00151, -0.10403, -0.07528]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports_part_01.geometry}
          material={nodes.keyboard_top_panel_ports_part_01.material}
          position={[-0.14748, -0.10414, -0.07459]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_bottom_panel_bolts01.geometry}
          material={materials.chrome_gold}
          position={[0, -0.10929, 0.0021]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_touchpad01.geometry}
          material={materials.aluminium_light}
          position={[0, -0.10463, 0.06264]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_bottom_panel01.geometry}
          material={colorMaterials.keyboard_bottom_panel01}
          position={[0, -0.10961, 0.00252]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.monitor_screen01.geometry}
          material={display}
          position={[0, 0.00223, -0.10482]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/3DModels/Laptops/new-macbook/scene.gltf");
