import React, { useEffect, useRef, useMemo } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(
    "/3DModels/Laptops/macbook_pro_m1_14/scene.gltf"
  );

  const colorMaterials = useMemo(() => {
    const list = [
      "keyboard_top_panel01",
      "keyboard_top_touchpad01",
      "Monitor_back_panel01",
      "keyboard_bottom_panel01",
      "keyboard_bottom_panel_part_04",
      "keyboard_top_panel_part_04",
      "keyboard_bottom_panel_part_02",
    ];

    return list.reduce((acc, name) => {
      let material = nodes[name]?.material || materials[name];
      acc[name] = material.clone();
      return acc;
    }, {});
  }, [nodes, materials]);

  const display = useMemo(() => materials.screen.clone(), [materials]);

  useEffect(() => {
    if (group.current) {
      props.init({
        ref: group.current,
        colorMaterials: Object.values(colorMaterials),
        display,
      });
    }
  }, []);

  return (
    <group ref={group} {...props}>
      <group
        scale={[0.1, 0.1, 0.1]}
        rotation={[0, -Math.PI, 0]}
        position={[0, -1.1, 0]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel01.geometry}
          material={colorMaterials.keyboard_top_panel01}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_touchpad01.geometry}
          material={colorMaterials.keyboard_top_touchpad01}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_buttons_part_01.geometry}
          material={nodes.keyboard_top_panel_buttons_part_01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_buttons01.geometry}
          material={nodes.keyboard_top_panel_buttons01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_buttons_part_02.geometry}
          material={materials.glossy_black}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_buttons_part_04.geometry}
          material={nodes.keyboard_top_panel_buttons_part_04.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_buttons_part_05.geometry}
          material={nodes.keyboard_top_panel_buttons_part_05.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_dynamic01.geometry}
          material={nodes.keyboard_top_panel_dynamic01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports01.geometry}
          material={nodes.keyboard_top_panel_ports01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports_part_01.geometry}
          material={nodes.keyboard_top_panel_ports_part_01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports_part_06.geometry}
          material={nodes.keyboard_top_panel_ports_part_06.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports_part_02.geometry}
          material={nodes.keyboard_top_panel_ports_part_02.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports_part_03.geometry}
          material={nodes.keyboard_top_panel_ports_part_03.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports_part_05.geometry}
          material={nodes.keyboard_top_panel_ports_part_05.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports_part_04.geometry}
          material={nodes.keyboard_top_panel_ports_part_04.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports_part_08.geometry}
          material={nodes.keyboard_top_panel_ports_part_08.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports_part_10.geometry}
          material={nodes.keyboard_top_panel_ports_part_10.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_part_01.geometry}
          material={nodes.keyboard_top_panel_part_01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports_part_07.geometry}
          material={nodes.keyboard_top_panel_ports_part_07.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports_part_11.geometry}
          material={nodes.keyboard_top_panel_ports_part_11.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_ports_part_12.geometry}
          material={nodes.keyboard_top_panel_ports_part_12.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_buttons_text01.geometry}
          material={materials.glossy_white}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_bottom_panel01.geometry}
          material={colorMaterials.keyboard_bottom_panel01}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_part_04.geometry}
          material={colorMaterials.keyboard_top_panel_part_04}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_top_panel_part_02.geometry}
          material={nodes.keyboard_top_panel_part_02.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_bottom_panel_part_01.geometry}
          material={nodes.keyboard_bottom_panel_part_01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_bottom_panel_part_04.geometry}
          material={colorMaterials.keyboard_bottom_panel_part_04}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_bottom_panel_part_02.geometry}
          material={colorMaterials.keyboard_bottom_panel_part_02}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.keyboard_bottom_panel_part_03.geometry}
          material={nodes.keyboard_bottom_panel_part_03.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Monitor_back_panel_logo01.geometry}
          material={colorMaterials.Monitor_back_panel_logo01}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Monitor_front_camera_border01.geometry}
          material={nodes.Monitor_front_camera_border01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Monitor_front_camera_border_part_01.geometry}
          material={nodes.Monitor_front_camera_border_part_01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Monitor_front_camera_lens01.geometry}
          material={materials.camera_lens}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        {/* <mesh
          castShadow
          receiveShadow
          geometry={nodes.Monitor_front_glass01.geometry}
          material={nodes.Monitor_front_glass01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        /> */}
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Monitor_front_panel01.geometry}
          material={materials.back_black}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Monitor_front_panel_border01.geometry}
          material={nodes.Monitor_front_panel_border01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Monitor_front_panel_screen_border01.geometry}
          material={materials.text_black}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Monitor_back_panel01.geometry}
          material={colorMaterials.Monitor_back_panel01}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Monitor_front_panel_screen01.geometry}
          material={display}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/3DModels/Laptops/macbook_pro_m1_14/scene.gltf");
