import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDevice,
  removeClass,
  setClassParam,
} from "../../../../../../slices/three-slice";
import ColorPicker from "../../../../../Common/ColorPicker/ColorPicker";
import DeleteButton from "../../../../../Common/DeleteButton/DeleteButton";
import GroupedSetting from "../../../../../Common/GroupedSetting/GroupedSetting";
import Select from "../../../../../Common/Select/Select";
import Slider from "../../../../../Common/Slider/Slider";
import UploadMedia from "../../../../../Common/UploadMedia/UploadMedia";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import * as styles from "./device.module.scss";
import * as THREE from 'three'

import IphoneImg from '../../../../../../assets/devices/iphone-12.png'
import Iphone13Img from '../../../../../../assets/devices/iphone-13.png'
import Iphone14Img from '../../../../../../assets/devices/iphone-14.png'
import IpadImg from '../../../../../../assets/devices/ipad.png'
import IpadMini6Img from '../../../../../../assets/devices/ipad-mini-6.png'
import ImacImg from '../../../../../../assets/devices/imac.png'
import GooglePixel5 from '../../../../../../assets/devices/google-pixel-5.png'
import GooglePixel6 from '../../../../../../assets/devices/google-pixel-6.png'
import MacBookProM114 from '../../../../../../assets/devices/macbook-pro-m1-14.png'
import MacBook from '../../../../../../assets/devices/macbook.png'
import { connectSliders } from "../Functions";
import { degrees_to_radians } from "../../../../../Common/Functions";
import { store } from "../../../../../../../configureStore";


import tinycolor from 'tinycolor2';
import DraggableSetting from "../../../../../Common/DraggableSetting/DraggableSetting";
import CustomColorPicker from "../../../../../Common/CustomColorPicker/CustomColorPicker";
import { EditableInput } from "react-color/lib/components/common";
import InlineOptions from "../../../../../Common/InlineOptions/InlineOptions";

const AddDevice = () => {
  const dispatch = useDispatch()

  const devices = [
    {
      name: 'iPhone 14',
      src: Iphone14Img
    },
    {
      name: 'iPhone 13',
      src: Iphone13Img
    },
    {
      name: 'iPhone 12',
      src: IphoneImg
    },
    {
      name: 'Google Pixel 5',
      src: GooglePixel5
    },
    {
      name: 'Google Pixel 6',
      src: GooglePixel6
    },
    {
      name: 'iPad',
      src: IpadImg
    },
    {
      name: 'iPad Mini 6',
      src: IpadMini6Img,
    },
    {
      name: 'MacBook Pro M1 14',
      src: MacBookProM114
    },
    {
      name: 'MacBook',
      src: MacBook
    },
    {
      name: 'iMac 24',
      src: ImacImg
    }
  ]

  function onClick(frame){
    dispatch(addDevice(frame))
  }
  return(
    <div>
      <span className={styles.title}>Add Device</span>
      <div className={styles.grid}>
      {devices.map((device) => 
        <div key={device.name} onClick={() => onClick(device.name)}>
          <div>
            <img
              src={device.src} 
            />
            <span>{device.name}</span>
          </div>
        </div>
      )}
      </div>
    </div>
  )
}

export default ({ id, timestamp }) => {
  const dispatch = useDispatch();

  const name = useSelector((state) => state.ThreeSlice.present.devices[id]?.name);
  const color = useSelector((state) => state.ThreeSlice.present.devices[id]?.color);
  const frame = useSelector((state) => state.ThreeSlice.present.devices[id]?.frame);
  const clay = useSelector((state) => state.ThreeSlice.present.devices[id]?.clay);
  const mode = useSelector((state) => state.ThreeSlice.present.devices[id]?.mode);
  const plane = useSelector(state => state.ThreeSlice.present.plane.active)

  const scene = useSelector((state) => state.ThreeSlice.present.scene);

  const [ref, setRef] = useState({})

  useEffect(() => {
    function updateRef(){
      let newRef = scene.getObjectByName(`devices-${id}`)
      if(newRef && newRef.uuid !== ref.uuid){
        setRef(newRef)
      }
      else{
        setTimeout(() => updateRef(), 100)
      }
    }
    updateRef()
  },  [frame, id])

  let sliderData = useMemo(() => {
      return id !== undefined ? [
      {
        id: `devices-${id}-scale`,
        path: 'scale.y'
      },
      {
        id: `images-${id}-positionX`,
        path: 'position.x'
      },
      {
        id: `images-${id}-positionY`,
        path: 'position.y'
      },
      {
        id: `images-${id}-positionZ`,
        path: 'position.z'
      },
      {
        id: `images-${id}-rotationX`,
        path: 'rotation.x'
      },
      {
        id: `images-${id}-rotationY`,
        path: 'rotation.y'
      },
      {
        id: `images-${id}-rotationZ`,
        path: 'rotation.z'
      },
    ] : []
}, [id])
  connectSliders(sliderData, ref)

  // if(timestamp !== undefined){
  //   var rotation = device.timestamps[timestamp].rotation 
  //   var position = device.timestamps[timestamp].position;
  // }

  function alignToPlane(){
    const ratios = {
      'iMac 24': 1.98,
      'iPhone 13': 2,
      'MacBook': 1.95,
      'Google Pixel 5': 1.9,
      'Google Pixel 6': 1.96,
      'iPad Mini 6': 2.01,
      'MacBook Pro M1 14': 2.055,
  }

    let y = store.getState().ThreeSlice.present.plane.height
    let box = new THREE.Box3().setFromObject(ref)
    let height = box.max.y - box.min.y
    let ratio = ratios[frame]
    ref.position.y = y + height/ratio
    changeParam("position", y + height/ratio, "y")
  }

  function changeParam(param, value, axis) {
    if (param === "rotation") {
      // let degree = degrees_to_radians(value);

      value = { x: ref.rotation.x, y: ref.rotation.y, z: ref.rotation.z, [axis]: degrees_to_radians(value) };
    } else if (param === "position") {
      value = { x: ref.position.x, y: ref.position.y, z: ref.position.z, [axis]: value };
    }
    dispatch(setClassParam({ param, value }));
  }

  function onChange(param, value, axis) {
    if (param === "rotation") {
      // let degree = degrees_to_radians(value);
      let newRotation = { x: ref.rotation.x, y: ref.rotation.y, z: ref.rotation.z, [axis]: degrees_to_radians(value)};
      let { x, y, z } = newRotation;
      ref.rotation.set(x, y, z);
    } else if (param === "position") {
      let newPosition = { x: ref.position.x, y: ref.position.y, z: ref.position.z, [axis]: value };
      let { x, y, z } = newPosition;
      ref.position.set(x, y, z);
    } else if (param === "scale") {
      ref.scale.set(value, value, value);
    }
  }

  function removeDevice() {
    dispatch(removeClass());
  }

  return (
    name ?
    <div className={styles.container}>
      <div className={styles.section}>
        <span className={styles.title}>Device</span>
        <div className={styles.settings}>
        <div className={styles.row}>
        <span>Screenshot</span>
            <UploadMedia
              video
              onUpload={(url) => changeParam("screenshot", url)}
              label={"Choose file"}
              style={{
                padding: 0,
                fontSize: 12,
                height: '100%'
              }}
            />
        </div>
        <div className={styles.row}>
        <span>Frame</span>
        <Select
        options={["Google Pixel 5","Google Pixel 6", "iPhone 14", "iPhone 13", "iPhone 12", "iPad", "iPad Mini 6", "MacBook", "MacBook Pro M1 14", "iMac 24"]}
        value={frame}
        onChange={(f) => changeParam("frame", f)}
      />
        </div>
        <div className={styles.row}>
            <span>Color</span>
            <div className={styles.colorContainer}>
            <DraggableSetting
            position={{x: 190, y: -28}}
            parent={
                <div className={styles.color}>
                  <div style={{backgroundColor: color}} />
                </div>
            } handle={'Color'}>
              <CustomColorPicker onColorChange={color => dispatch(setClassParam({ param: "color", value: color }))} color={color} />
            </DraggableSetting>
            <div className={styles.input}>
                    <EditableInput
                    value={ '#' + tinycolor(color).toHex() }
                    onChange={ (color) => dispatch(setClassParam({ param: "color", value: tinycolor(color).toRgbString() }))} />
            </div>
            </div>
          </div>
          <div className={styles.row}>
        <span>Texture</span>
        <InlineOptions active={clay} onChange={(value) => dispatch(setClassParam({ param: "clay", value }))} options={[
          {
            display: 'Metal',
            value: false
          },
          {
            display: 'Clay',
            value: true
          }
        ]} />
        </div>
        <div className={styles.row}>
        <span>Scale</span>
        <Slider
       id={`devices-${id}-scale`}
        min={0}
        max={5}
        step={0.01}
        // value={device.scale * 10}
        onEnd={(value) => changeParam("scale", value)}
        onChange={(value) => onChange("scale", value)}
      />
        </div>
        <div className={styles.row}>
        <span>Mode</span>
        <InlineOptions active={mode || 'move'} onChange={(value) => changeParam("mode", value)} options={[
          {
            display: 'Move',
            value: 'move'
          },
          {
            display: 'Rotate',
            value: 'rotate'
          }
        ]} />
        </div>
        </div>
        </div>
        <div className={styles.section}>
        <span className={styles.title}>Position</span>
        <div className={styles.settings}>
        <div className={styles.row}>
        <span>X</span>
          <Slider
          id={`images-${id}-positionX`}
            className
            min={-10}
            max={10}
            step={0.001}
            // value={position.x}
            onEnd={(value) => changeParam("position", value, "x")}
            onChange={(value) => onChange("position", value, "x")}
          />
          </div>
          <div className={styles.row}>
        <span>Y</span>
          <Slider
            id={`images-${id}-positionY`}
            min={-10}
            max={10}
            step={0.001}
            // value={position.y}
            onEnd={(value) => changeParam("position", value, "y")}
            onChange={(value) => onChange("position", value, "y")}
          />
          </div>
          <div className={styles.row}>
        <span>Z</span>
          <Slider
                  id={`images-${id}-positionZ`}
            min={-10}
            max={10}
            step={0.001}
            // value={position.z}
            onEnd={(value) => changeParam("position", value, "z")}
            onChange={(value) => onChange("position", value, "z")}
          />
          </div>
          {plane ? 
          <button className={styles.planeButton} onClick={alignToPlane}>Align to plane</button> : null}
        </div>
        </div>
        <div className={styles.section}>
        <span className={styles.title}>Rotation</span>
        <div className={styles.settings}>
        <div className={styles.row}>
        <span>X</span>
        <Slider
          id={`images-${id}-rotationX`}
          min={-360}
          max={360}
          step={0.001}
          // value={radians_to_degrees(rotation.x)}
          onEnd={(value) => changeParam("rotation", value, "x")}
          onChange={(value) => onChange("rotation", value, "x")}
        />
          </div>
          <div className={styles.row}>
        <span>Y</span>
        <Slider
          id={`images-${id}-rotationY`}
          min={-360}
          max={360}
          step={0.001}
          className={styles.slider}
          // value={radians_to_degrees(rotation.y)}
          onEnd={(value) => changeParam("rotation", value, "y")}
          onChange={(value) => onChange("rotation", value, "y")}
        />
          </div>
          <div className={styles.row}>
        <span>Z</span>
        <Slider
          id={`images-${id}-rotationZ`}
          min={-360}
          max={360}
          step={0.001}
          className={styles.slider}
          // value={radians_to_degrees(rotation.z)}
          onEnd={(value) => changeParam("rotation", value, "z")}
          onChange={(value) => onChange("rotation", value, "z")}
        />
          </div>
        </div>
        </div>
      <DeleteButton onClick={removeDevice} style={{ marginTop: 10 }} />
    </div>
     : <AddDevice />
  );
};
