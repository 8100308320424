/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useMemo, useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/3DModels/Phones/google_pixel_5/scene.gltf')

  const colorMaterials = useMemo(() => {
    return ['mat_black_rubber'].reduce((acc, key) => {
        acc[key] = materials[key].clone()
        return acc
    }, {})
  },[materials])

  const display = useMemo(() => materials.screen.clone(), [materials])

  useEffect(() => {
    if(group.current){
      props.init({ref: group.current, colorMaterials: Object.values(colorMaterials), display})
    }
  }, [])

  return (
    <group ref={group} {...props}>
      <group scale={[0.2,0.2,0.2]}>
        <mesh geometry={nodes.back_panel01.geometry} material={colorMaterials.mat_black_rubber} />
        {/* <mesh geometry={nodes.front_glass01.geometry} material={materials.glass} /> */}
        <mesh geometry={nodes.front_panel_border01.geometry} material={materials.glossy_black} />
        <mesh geometry={nodes.front_panel01.geometry} material={materials.back_black} />
        <mesh geometry={nodes.screen01.geometry} material={display} />
        <mesh geometry={nodes.screen_border01.geometry} material={materials.text_black} />
        <mesh geometry={nodes.front_camera_border01.geometry} material={materials.mat_black} />
        <mesh geometry={nodes.front_camera_border_part_01.geometry} material={materials.mat_gray_dark} />
        <mesh geometry={nodes.front_camera_lens01.geometry} material={materials.camera_lens} />
        <mesh geometry={nodes.back_camera_border_part_01.geometry} material={materials.glossy_black} />
        <mesh geometry={nodes.back_panel_part_01.geometry} material={materials.mat_black_rubber} />
        <mesh geometry={nodes.back_camera_glass01.geometry} material={materials.glass} />
        <mesh geometry={nodes.back_flash_border_part_01.geometry} material={materials.glossy_black} />
        <mesh geometry={nodes.back_camera_border01.geometry} material={materials.back_black} />
        <mesh geometry={nodes.back_flash_glass01.geometry} material={materials.glass} />
        <mesh geometry={nodes.back_flash_border_part_03.geometry} material={materials.chrome} />
        <mesh geometry={nodes.back_camera_border_part_03.geometry} material={materials.mat_gray_dark} />
        <mesh geometry={nodes.back_camera_lens01.geometry} material={materials.camera_lens} />
        <mesh geometry={nodes.back_panel_logo01.geometry} material={materials.aluminium_dark} />
        <mesh geometry={nodes.back_panel_dynamic01.geometry} material={materials.glossy_black} />
        <mesh geometry={nodes.back_panel_part_02.geometry} material={materials.mat_black} />
        <mesh geometry={nodes.back_panel_power_button01.geometry} material={materials.chrome_dark} />
        <mesh geometry={nodes.back_panel_volume_button01.geometry} material={materials.mat_black_rubber} />
        <mesh geometry={nodes.mini_usb01.geometry} material={materials.chrome} />
        <mesh geometry={nodes.mini_usb_part_01.geometry} material={materials.gold} />
        <mesh geometry={nodes.mini_usb_part_03.geometry} material={materials.mat_black} />
        <mesh geometry={nodes.back_panel_bottom_dynamic01.geometry} material={materials.glossy_black} />
        <mesh geometry={nodes.mini_usb_part_02.geometry} material={materials.mat_black} />
        <mesh geometry={nodes.back_flash_border01.geometry} material={materials.chrome_flash} />
        <mesh geometry={nodes.back_flash_lens01.geometry} material={materials.glossy_orange} />
        <mesh geometry={nodes.back_flash_lens_part_01.geometry} material={materials.glossy_yellow} />
      </group>
    </group>
  )
}

useGLTF.preload('/3DModels/Phones/google_pixel_5/scene.gltf')
