import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { store } from '../../../../../../configureStore'
import { setDimensions, setDimensionsModal } from '../../../../../slices/three-slice'
import Button from '../../../../Common/Button/Button'
import Modal from '../../../../Modals/Modal/Modal'
import * as styles from './recording.module.scss'

export default () => {
    let status = useSelector(state => state.ThreeSlice.present.recording.status)
    let progress = useSelector(state => state.ThreeSlice.present.recording.progress)
    let text = useSelector(state => state.ThreeSlice.present.recording.text)

    return(
        <Modal open={status} canClose={false} iconStyle={{right: 5, top: 5}}>
            <div className={styles.container}>
                <h3>{text}</h3>
                <h4>{progress} %</h4>
                <div className={styles.progressBar}>
                    <div style={{width: `${progress}%`}} />
                </div>
                <span>This process can take a long time. Please be patient.</span>
            </div>
        </Modal>
    )
}