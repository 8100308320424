import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseSvg from '../../../../assets/svg/3d-maker/close.svg'
import { setEditor } from '../../../../slices/three-slice'
import Components from './Components/Components'
import * as styles from './editor.module.scss'
import CurrentTab from './Tabs/CurrentTab'

const Editor = ({height}) => {
    const animated = useSelector((state) => state.ThreeSlice.present.animated);
    const dispatch = useDispatch()
    
    return(
        <div className={styles.container}>
            <CurrentTab />
            {animated ? null : <Components />}
        </div>
    )
}

export default Editor;