
import React, { useRef, useMemo, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
    const group = useRef()
    const { nodes, materials } = useGLTF('/3DModels/Phones/iphone_14/scene.gltf')
  
    const colorMaterials = useMemo(() => {
      const list = ["mat_black.001", "metal_black", "aluminium_black"]
  
      return list.reduce((acc, name) => {
          let material =  nodes[name]?.material || materials[name]
          acc[name] = material.clone()
          return acc
      }, {})
    },[nodes, materials])
  
    const display = useMemo(() => materials.screen.clone(), [materials])
  
    useEffect(() => {
      if(group.current){
        props.init({ref: group.current, colorMaterials: Object.values(colorMaterials), display})
      }
    }, [])
  return (
    <group ref={group} {...props}>
      <group scale={[0.18, 0.18, 0.18]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_border01.geometry}
          material={materials.plastic_black}
          position={[1.2527, 4.9839, -0.5346]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_border_part_01.geometry}
          material={materials.metal_black}
          position={[1.2533, 4.9811, -0.4412]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_border_part_02.geometry}
          material={materials.back_black}
          position={[1.2533, 4.9811, -0.5451]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_border_part_03.geometry}
          material={materials.mat_plastic_gray_dark}
          position={[1.2979, 4.8052, -0.4234]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_glass01.geometry}
          material={materials.glass}
          position={[1.2533, 4.9811, -0.5798]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_lens_glass01.geometry}
          material={materials.glass}
          position={[0.3562, 4.9782, -0.4623]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_flash_border01.geometry}
          material={materials.glass_falsh}
          position={[0.3592, 6.3364, -0.2819]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_flash_border_part_01.geometry}
          material={materials.chrome}
          position={[0.3592, 6.3365, -0.2273]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_flash_glass01.geometry}
          material={materials.glass}
          position={[0.3592, 6.3365, -0.3554]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_flash_lens01.geometry}
          material={materials.mat_white}
          position={[0.3592, 6.3364, -0.261]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_flash_lens02.geometry}
          material={materials.glossy_yellow}
          position={[0.3592, 6.3364, -0.2495]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_glass01.geometry}
          material={materials.matte_glass}
          position={[0.0026, 0, -0.2896]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_glass_part_01.geometry}
          material={materials.glass}
          position={[1.2327, 4.9803, -0.37]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_panel01.geometry}
          material={colorMaterials["mat_black.001"]}
          position={[0.0026, 0, -0.2682]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_panel_logo01.geometry}
          material={materials.chrome_logo_black}
          position={[-0.0284, 0.087, -0.1822]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_panel_part_01.geometry}
          material={colorMaterials["mat_black.001"]}
          position={[1.2327, 4.9803, -0.332]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_panel_part_02.geometry}
          material={materials.mat_black}
          position={[1.1943, 4.7263, -0.0845]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.body_side_panel01.geometry}
          material={colorMaterials.metal_black}
          position={[0.0026, 0, 0.1923]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.body_side_panel_bolts01.geometry}
          material={materials.chrome}
          position={[0.0026, -7.3538, 0.1933]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.body_side_panel_button01.geometry}
          material={colorMaterials.metal_black}
          position={[3.5953, 1.8154, 0.1923]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.body_side_panel_buttons_part_01.geometry}
          material={colorMaterials.metal_black}
          position={[-3.5879, 2.1784, 0.1923]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.body_side_panel_buttons_part_02.geometry}
          material={colorMaterials.metal_black}
          position={[-3.5662, 4.2362, 0.191]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.body_side_panel_dynamic01.geometry}
          material={materials.glossy_black}
          position={[0.2164, -7.3227, 0.1917]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.body_side_panel_part_01.geometry}
          material={materials.aluminium_black}
          position={[0.0026, 0, 0.1923]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.body_side_panel_part_02.geometry}
          material={colorMaterials.aluminium_black}
          position={[3.5776, -2.0096, 0.1923]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.body_side_panel_part_03.geometry}
          material={materials.mat_black}
          position={[-0.0355, -1.8496, 0.183]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.front_camera_border01.geometry}
          material={materials.mat_plastic_gray_dark}
          position={[0.7394, 6.5515, 0.4581]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.front_camera_border_part_01.geometry}
          material={materials.back_black}
          position={[0.7358, 6.5419, 0.4957]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.front_camera_part_01.geometry}
          material={materials.mat_black}
          position={[0.0026, 6.7567, 0.4533]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.front_glass01.geometry}
          material={materials.glass}
          position={[0.0026, 0, 0.5783]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.front_panel01.geometry}
          material={materials.back_black}
          position={[0.0026, 0, 0.5432]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.screen_border01.geometry}
          material={materials.text_black}
          position={[0.0026, 0, 0.5418]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.screen_part_01.geometry}
          material={materials.mat_black}
          position={[0.0026, 6.5548, 0.5261]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.screen_part_03.geometry}
          material={materials.mat_gray_dark}
          position={[-0.3694, 6.5548, 0.5418]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.usb01.geometry}
          material={materials.chrome}
          position={[0.0023, -6.943, 0.1923]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.usb_part_01.geometry}
          material={materials.gold}
          position={[0.0034, -6.9511, 0.1672]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_lens01.geometry}
          material={materials.camera_lens}
          position={[1.307, 4.963, -0.4122]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.front_camera_lens01.geometry}
          material={materials.camera_lens}
          position={[0.7394, 6.5515, 0.4441]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.screen01.geometry}
          material={display}
          position={[0.0026, 0, 0.5418]}
        />
      </group>
    </group>
  );
}

useGLTF.preload('/3DModels/Phones/iphone_14/scene.gltf')