import React, { useEffect, useMemo } from 'react'
import * as styles from './header.module.scss'
import PlayIcon from '../../../../../assets/svg/3d-maker/play.svg'
import PauseIcon from '../../../../../assets/svg/3d-maker/pause.svg'
import ToStartIcon from '../../../../../assets/svg/3d-maker/player-to-start.svg'
import ToEndIcon from '../../../../../assets/svg/3d-maker/player-to-end.svg'
import {setCurrentTime, setDuration, togglePlay, addTimestamp, deleteTimestamp} from '../../../../../slices/three-slice'
import { useDispatch, useSelector } from 'react-redux'
import LayerZoom from "./Zoom/Zoom";

// const AddRemoveCheckpoint = () => {
//     const dispatch = useDispatch()
//     let currentItem = useSelector(state => state.ThreeSlice.present.currentItem)

//     return(
//         <div className={`${styles.addRemove} ${currentItem.timestamp !== undefined ? styles.current : undefined}`}>
//             <button onClick={() => currentItem.class && !currentItem.timestamp ? dispatch(addTimestamp()) : null}>Add Checkpoint</button>
//             <button onClick={() => dispatch(deleteTimestamp())}>Remove Checkpoint</button>
//         </div>
//     )
// }

const PausePlay = () => {
    const dispatch = useDispatch()
    let playing = useSelector(state => state.ThreeSlice.present.playing)
    let duration = useSelector(state => state.ThreeSlice.present.duration)

    useEffect(() => {
        function onSpace(e){
            if (e.code === 'Space') {
                let exclude = ['input', 'textarea'];
                if (exclude.indexOf(e.target.tagName.toLowerCase()) === -1) {
                    dispatch(togglePlay())
                }
            }
        }
        document.addEventListener('keyup', onSpace)
        return () => document.removeEventListener('keyup', onSpace)
    }, [])
    return(
        <div className={styles.pausePlay}>
            <ToStartIcon onClick={() => dispatch(setCurrentTime(0))}/>
            <div onClick={() => dispatch(togglePlay())}>
                {playing ? <PauseIcon />  : <PlayIcon />}
            </div>
            <ToEndIcon onClick={() => dispatch(setCurrentTime(duration))}/>
        </div>
    )
}

const Duration = () => {
    const dispatch = useDispatch()
    let duration = useSelector(state => state.ThreeSlice.present.duration)
    return(
        <div className={styles.duration}>
            <div>Duration</div>
            <div>
                <input type={'number'} value={duration} onChange={e => dispatch(setDuration(parseInt(e.target.value)))}/>
                <div>ms</div>
            </div>

        </div>
    )
}

export default () => {
    return(
        <div className={styles.container}>
            {/* <AddRemoveCheckpoint /> */}
            <LayerZoom />
            <PausePlay />
            <Duration />
        </div>
    )
}
