// extracted by mini-css-extract-plugin
export const animations = "layer-module--animations--1aiLf";
export const selectedTimestamp = "layer-module--selected-timestamp--q1qob";
export const layer = "layer-module--layer--3qLbh";
export const devices = "layer-module--devices--nkh3H";
export const inner = "layer-module--inner--3Ek9E";
export const animationLayer = "layer-module--animation-layer--AzbQV";
export const timestamp = "layer-module--timestamp--bTl-f";
export const texts = "layer-module--texts--SM9q9";
export const images = "layer-module--images--2KJSs";
export const camera = "layer-module--camera--1rsgJ";
export const plane = "layer-module--plane--2qf38";