import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CubeIcon from '../../../../../assets/svg/3d-maker/header/cube.svg'
import CameraIcon from '../../../../../assets/svg/3d-maker/header/camera.svg'
import TextIcon from '../../../../../assets/svg/3d-maker/header/text.svg'
import ImageIcon from '../../../../../assets/svg/3d-maker/header/image.svg'
import PlaneIcon from '../../../../../assets/svg/3d-maker/header/square.svg'
import * as styles from './components.module.scss'
import { setCurrentItem } from '../../../../../slices/three-slice'


const Components = () => {
    let dispatch = useDispatch()
    const currentItem = useSelector((state) => state.ThreeSlice.present.currentItem);
    const devices = useSelector((state) => state.ThreeSlice.present.devices);
    const texts = useSelector((state) => state.ThreeSlice.present.texts);
    const images = useSelector((state) => state.ThreeSlice.present.images);
    const plane = useSelector((state) => state.ThreeSlice.present.plane);

    let items = [{name: 'Camera', class: 'camera'}, plane.active ? plane : {}, ...texts, ...images, ...devices ]

    return(
        <div className={styles.outer}>
            <div className={styles.inner}>
                <span className={styles.title}>Components</span>
                <div className={styles.list}>
                    {items.map((item, index) => {
                        if(!item.class) return
                        return(
                            <div key={index} className={`${styles.row} ${currentItem.class === item.class && currentItem.id === item.id ? styles.active : undefined}`} onClick={() => dispatch(setCurrentItem({ class: item.class, id: item.id }))}>
                                <div>
                                    {item.class === 'devices' ? <CubeIcon fill={'#009688'} /> : item.class === 'camera' ? <CameraIcon fill={'#8e7dbe'} /> : item.class === 'texts' ? <TextIcon /> : item.class === 'images' ? <ImageIcon /> : <PlaneIcon fill={'#795548'} />}
                                    <span>
                                    {item.name}
                                    </span>
                                </div>

                            </div>
                        )
                    })}
                </div>
            </div>
        </div>    
    )
}

export default Components;