import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setErrorModal } from '../../../../../slices/three-slice'
import Modal from '../../../../Modals/Modal/Modal'
import * as styles from './error.module.scss'

export default () => {
    const dispatch = useDispatch()
    let open = useSelector(state => state.ThreeSlice.present.errorModal)

    function handleClose(){
        dispatch(setErrorModal(false))
    }
    return(
        <Modal open={open} handleClose={handleClose} iconStyle={{right: 5, top: 5}}>
            <div className={styles.container}>
                It looks like you're trying to download a video on a non-supported browser.<br />Please use Google Chrome.
            </div>
        </Modal>
    )
}