import { useGLTF } from "@react-three/drei";
import React, { useMemo, useRef, useEffect } from "react";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(
    "/3DModels/Phones/google_pixel_6/scene.gltf"
  );
  const colorMaterials = useMemo(() => {
    const list = [
      "back_panel_part_01",
      "glossy_white",
      "back_panel_logo01",
      "body_side_panel01",
      "mat_gray",
      "body_side_panel_buttons01",
      "mini_usb01",
    ];

    return list.reduce((acc, name) => {
      let material = nodes[name]?.material || materials[name];
      acc[name] = material.clone();
      return acc;
    }, {});
  }, [nodes, materials]);

  const display = useMemo(() => materials.Material__30.clone(), [materials]);

  useEffect(() => {
    if (group.current) {
      props.init({
        ref: group.current,
        colorMaterials: Object.values(colorMaterials),
        display,
      });
    }
  }, []);

  return (
    <group ref={group} {...props}>
      <group
        rotation={[0, -Math.PI, 0]}
        scale={[0.18, 0.18, 0.18]}
        position={[0, -1.5, 0]}
      >
        {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.front_glass01.geometry}
        material={colorMaterials.front_glass01}
        rotation={[-Math.PI, 0, -Math.PI]}
      /> */}
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.body_side_panel01.geometry}
          material={colorMaterials.body_side_panel01}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.front_panel01.geometry}
          material={nodes.front_panel01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.screen_border01.geometry}
          material={materials.text_black}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.front_camera_border01.geometry}
          material={nodes.front_camera_border01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.front_camera_lens01.geometry}
          material={nodes.front_camera_lens01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.front_camera_border_part_01.geometry}
          material={nodes.front_camera_border_part_01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_glass01.geometry}
          material={nodes.back_glass01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_part_01.geometry}
          material={materials.glossy_black}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_panel_part_01.geometry}
          material={colorMaterials.back_panel_part_01}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_panel01.geometry}
          material={colorMaterials.glossy_white}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_panel_logo01.geometry}
          material={colorMaterials.back_panel_logo01}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_glass01.geometry}
          material={nodes.back_camera_glass01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_border01.geometry}
          material={nodes.back_camera_border01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_flash_border01.geometry}
          material={nodes.back_flash_border01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_border_part_01.geometry}
          material={nodes.back_camera_border_part_01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_lens01.geometry}
          material={nodes.back_camera_lens01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_lens_part_01.geometry}
          material={nodes.back_camera_lens_part_01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_part_02.geometry}
          material={nodes.back_camera_part_02.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_camera_part_04.geometry}
          material={nodes.back_camera_part_04.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_flash_border02.geometry}
          material={materials.chrome_flash}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.back_flash_lens01.geometry}
          material={materials.glossy_yellow}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.body_side_panel_part_01.geometry}
          material={colorMaterials.mat_gray}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.body_side_panel_buttons01.geometry}
          material={colorMaterials.body_side_panel_buttons01}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.body_side_panel_part_02.geometry}
          material={nodes.body_side_panel_part_02.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.body_side_panel_part_04.geometry}
          material={nodes.body_side_panel_part_04.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mini_usb01.geometry}
          material={colorMaterials.mini_usb01}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mini_usb_part_01.geometry}
          material={materials.gold}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mini_usb_part_03.geometry}
          material={nodes.mini_usb_part_03.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.front_panel_dynamic01.geometry}
          material={nodes.front_panel_dynamic01.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mini_usb_part_02.geometry}
          material={nodes.mini_usb_part_02.material}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.screen01.geometry}
          material={display}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/3DModels/Phones/google_pixel_6/scene.gltf");
