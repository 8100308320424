import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addImage,
  removeClass,
  setClassParam,
} from "../../../../../../slices/three-slice";
import CollectionComponent from "../../../../../Common/CollectionComponent/CollectionComponent";
import DeleteButton from "../../../../../Common/DeleteButton/DeleteButton";
import { degrees_to_radians } from "../../../../../Common/Functions";
import GroupedSetting from "../../../../../Common/GroupedSetting/GroupedSetting";
import Slider from "../../../../../Common/Slider/Slider";
import UploadMedia from "../../../../../Common/UploadMedia/UploadMedia";
import { connectSliders } from "../Functions";
import * as styles from './image.module.scss'

const AddImage = () => {
  const dispatch = useDispatch();
  function addNew(src) {
    dispatch(addImage(src));
  }
  async function getData(src) {
    let blob = await fetch(src).then((r) => r.blob());
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    addNew(dataUrl);
  }
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <span className={styles.title}>Add Image</span>
        <UploadMedia label={"Upload Image"} onUpload={addNew} />
        {/* <CollectionComponent
          requiredCollections={["Store Badges"]}
          onSelect={getData}
          canvasProportion={0}
          style={{
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        /> */}
    </div>
    </div>
  );
};

const ImageTab = ({ id }) => {
  const dispatch = useDispatch();
  const name = useSelector((state) => state.ThreeSlice.present.images[id]?.name);
  const ratio = useSelector((state) => state.ThreeSlice.present.images[id]?.ratio);
  const scene = useSelector((state) => state.ThreeSlice.present.scene);

  const [ref, setRef] = useState({});

  useEffect(() => {
    function updateRef() {
      let newRef = scene.getObjectByName(`images-${id}`);
      if (newRef) {
        setRef(newRef);
      } else {
        setTimeout(() => updateRef(), 100);
      }
    }
    updateRef();
  }, [id]);

  let sliderData = useMemo(() => {
    return id !== undefined
      ? [
          {
            id: `images-${id}-scale`,
            path: "scale.y",
          },
          {
            id: `images-${id}-positionX`,
            path: "position.x",
          },
          {
            id: `images-${id}-positionY`,
            path: "position.y",
          },
          {
            id: `images-${id}-positionZ`,
            path: "position.z",
          },
          {
            id: `images-${id}-rotation`,
            path: "material.rotation",
          },
          {
            id: `images-${id}-opacity`,
            path: "material.opacity",
          },
        ]
      : [];
  }, [id]);
  connectSliders(sliderData, ref);

  function changeParam(param, value, axis) {
    if (param === "position") {
      value = { ...ref.position, [axis]: value };
    }
    dispatch(setClassParam({ param, value }));
  }

  function onChange(param, value, axis) {
    if (param === "position") {
      let newPosition = { ...ref.position, [axis]: value };
      let { x, y, z } = newPosition;
      ref.position.set(x, y, z);
    } else if (param === "scale") {
      ref.scale.set(ref.userData.ratio * value, value, 1);
    } else if (param === "opacity") {
      ref.material[param] = value;
    } else if (param === "rotation"){
      value = degrees_to_radians(value)
      ref.material[param] = value;
    }
  }

  return name ? (
    <div className={styles.container}>
      <div className={styles.section}>
        <span className={styles.title}>Image</span>
        <div className={styles.settings}>
        <div className={styles.row}>
          <span>Source</span>
          <UploadMedia
            label={"Choose file"}
            style={{
              padding: 0,
              fontSize: 12,
              height: '100%'
            }}
            onUpload={(src) =>
              dispatch(setClassParam({ param: "image", value: src }))
            }
          />
          </div>
          <div className={styles.row}>
          <span>Size</span>
          <Slider
            id={`images-${id}-scale`}
            min={0}
            max={2}
            step={0.01}
            // value={image.scale}
            onEnd={(value) => changeParam("scale", value)}
            onChange={(value) => onChange("scale", value)}
          />
          </div>
          <div className={styles.row}>
          <span>Rotation</span>
          <Slider
            id={`images-${id}-rotation`}
            min={-360}
            max={360}
            step={0.01}
            // value={image.rotation}
            onEnd={(value) => changeParam("rotation", value)}
            onChange={(value) => onChange("rotation", value)}
          />
          </div>
          <div className={styles.row}>
          <span>Opacity</span>
          <Slider
            id={`images-${id}-opacity`}
            min={0}
            max={1}
            step={0.01}
            // value={image.opacity}
            onEnd={(value) => changeParam("opacity", value)}
            onChange={(value) => onChange("opacity", value)}
          />
          </div>
          </div>
          </div>
          <div className={styles.section}>
        <span className={styles.title}>Position</span>
        <div className={styles.settings}>
        <div className={styles.row}>
        <span>X</span>
        <Slider
          id={`images-${id}-positionX`}
          min={-10}
          max={10}
          step={0.001}
          // value={image.position.x}
          onEnd={(value) => changeParam("position", value, "x")}
          onChange={(value) => onChange("position", value, "x")}
        />
          </div>
          <div className={styles.row}>
        <span>Y</span>
        <Slider
          id={`images-${id}-positionY`}
          min={-10}
          max={10}
          step={0.001}
          // value={image.position.y}
          onEnd={(value) => changeParam("position", value, "y")}
          onChange={(value) => onChange("position", value, "y")}
        />
          </div>
          <div className={styles.row}>
        <span>Z</span>
        <Slider
          id={`images-${id}-positionZ`}
          min={-10}
          max={10}
          step={0.001}
          // value={image.position.z}
          onEnd={(value) => changeParam("position", value, "z")}
          onChange={(value) => onChange("position", value, "z")}
        />
          </div>
        </div>
        </div>
      <DeleteButton
        onClick={() => dispatch(removeClass())}
        style={{ marginTop: 10 }}
      />
    </div>
  ) : (
    <AddImage />
  );
};

export default ImageTab;
