import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGesture } from "react-use-gesture";
import { store } from "../../../../../../../configureStore";
import DiamondIcon from "../../../../../../assets/svg/3d-maker/new-diamond.svg";
import {
    changeTimeStamp,
    setCurrentItem,
    setCurrentTime,
    setCurrentTimestamp,
    setObjectAnimationParam,
    updateTime
} from "../../../../../../slices/three-slice";
import {closest} from '../../../../../Common/Functions'
import Select from "../../../../../Common/Select/Select";
import * as styles from "./layer.module.scss";

const Timestamp = ({item, start, end, selected, onChange, onSelect}) => {
  let dispatch = useDispatch();
  let layerZoom = useSelector((state) => state.ThreeSlice.present.layerZoom);

  let ref = useRef()

  const initialLeft = item.time * layerZoom
  const bounds = {left: -item.time*layerZoom, right: (end - item.time)*layerZoom}

  function onClick(){
    dispatch(setCurrentTime(start + item.time))
    onSelect()
  }

  // var timestamps = []

  const bindDrag = useGesture(
    {
      onDragStart: (state) => {
        // timestamps = findAllTimestamps(time)
      },
      onDrag: (state) => {
          let x = state.movement[0];
          let shifted = state.event.shiftKey
          // if(shifted){
          //   let closestTimestamp = closest(initialLeft + x, timestamps)
          //   if(Math.abs(initialLeft + x - closestTimestamp) < 30 && closestTimestamp / layerZoom !== start && closestTimestamp / layerZoom !== end ){
          //     x = closestTimestamp - initialLeft
          //   }
          // }
          // Change css
          ref.current.style.left = (item.time + x/layerZoom) * 100 / (end-start) + '%'
          // if (index === 0) {
          //   // If first --> increase width + reduce left
          //   layerRef.current.style.marginLeft = initialStart + x + "px";
          //   layerRef.current.style.width = initialWidth - x + "px";
          // } else if (index === item.timestamps.length - 1) {
          //   // If last --> increase width
          //   layerRef.current.style.width = initialWidth + x + "px";
          // }  
      },
      onDragEnd: () => {
          let finalTime = parseFloat(ref.current.style.left) * (end-start)/100
          onChange(finalTime)
      },
    },
    {
      drag: {
        bounds,
        filterTaps: true,
      },
    }
  );
  return(
    <div className={`${styles.timestamp} ${selected ? styles.selectedTimestamp : undefined}`} onClick={onClick} style={{ left: `${item.time * 100 / (end - start)}%` }} ref={ref}  {...bindDrag()} />
  )
}

export default ({ item, timestamps }) => {
  let dispatch = useDispatch();
  let layerZoom = useSelector((state) => state.ThreeSlice.present.layerZoom);
  let duration = useSelector((state) => state.ThreeSlice.present.duration);
  let currentItem = useSelector((state) => state.ThreeSlice.present.currentItem);
  let currentTimestamp = useSelector((state) => state.ThreeSlice.present.currentTimestamp);

  const start = item.class !== 'camera' ? item.time.start : 0
  const end = item.class !== 'camera' ? item.time.end : duration

  const ref = useRef()

  const selected = useMemo(() => {
    if (currentItem.class === item.class && currentItem.id === item.id) {
      return true;
    }
    return false;
  }, [item, currentItem]);

  function selectedTimestamp(key, index){
      if (currentTimestamp.class === item.class && currentTimestamp.id === item.id && currentTimestamp.index === index && currentTimestamp.key === key) {
        return true;
      }
      return false
  }


  function onKeyframeClick() {
    dispatch(setCurrentItem({ class: item.class, id: item.id }));
  }


  const bindDrag = useGesture(
    {
      onDragStart: (state) => {
      },
      onDrag: (state) => {
          let x = state.movement[0];
          ref.current.style.marginLeft = (start * layerZoom) + x + 'px'
      },
      onDragEnd: (state) => {
        // Save new time
        let newStart = parseFloat(ref.current.style.marginLeft) / layerZoom
        let newEnd = newStart + parseFloat(ref.current.style.width) / layerZoom

        dispatch(updateTime({class: item.class, id: item.id, value: {start: newStart, end: newEnd}}))
      },
    },
    {
      drag: {
        bounds: {left: -(start * layerZoom)},
        filterTaps: true,
        enabled: item.class !== 'camera'
      },
    }
  );

  const bindTimerange = (index) => useGesture(
    {
      onDragStart: (state) => {
      },
      onDrag: (state) => {
          state.event.stopPropagation()
          let x = state.movement[0];
          let shifted = state.event.shiftKey

          if(!shifted){
            let val = index === 0 ? start : end
            let closestTimestamp = closest(val + x/layerZoom, [...timestamps, duration])
            if(Math.abs((val + x/layerZoom) - closestTimestamp) * layerZoom < 30){
              x = (closestTimestamp - val) * layerZoom
            }
          }

          // Increase/reduce margin + width
          if(index === 0){
            ref.current.style.marginLeft = (start * layerZoom) + x + 'px'
            ref.current.style.width = ((end - start) * layerZoom) - x + 'px'
          }
          else {
            ref.current.style.width = ((end - start) * layerZoom) + x + 'px'
          }

      },
      onDragEnd: (state) => {
        // Save new time
        let newStart = parseFloat(ref.current.style.marginLeft) / layerZoom
        let newEnd = newStart + parseFloat(ref.current.style.width) / layerZoom

        dispatch(updateTime({class: item.class, id: item.id, value: {start: newStart, end: newEnd}}))
      },
    },
    {
      drag: {
        bounds: {
          left: index === 0 ? -item.time.start * layerZoom : (item.time.start - item.time.end) * layerZoom + 50,
          right: index === 0 ? (item.time.end - item.time.start) * layerZoom - 50 : Infinity
        },
        enabled: item.class !== 'camera'
      },
    }
  );

  function onTimeChange(key, index, value){
    dispatch(setObjectAnimationParam({class: item.class, id: item.id, animation: key, index, param: 'time', value}))
  }

  function onSelect(key, index){
    dispatch(setCurrentTimestamp({class: item.class, id: item.id, key, index}))
  }

  const TimeRange = () => {
    if(item.class === 'camera'){
      return null
    }
    else {
      return (
          <>
            <div {...bindTimerange(0)()}/>
            <div {...bindTimerange(1)()}/>
          </>
      )
    }
  }

  return (
    <div ref={ref} className={`${styles.layer} ${styles[item.class]}`} style={{
      marginLeft: start * layerZoom,
      width: (end - start) * layerZoom,
    }}>
      <div
        {...bindDrag()}
        className={`${styles.inner} ${selected ? styles.selected : undefined}`}
        onClick={onKeyframeClick}
      >
        <TimeRange />
      </div>
      {item.animationTool ?
      <div className={styles.animations}>
          {Object.keys(item.animations).map((key) => {
            return <div className={styles.animationLayer} key={key}>
              {item.animations[key].length ? item.animations[key].map((el, i) => {
                return <Timestamp
                bounds={{left: (start - el.time)*layerZoom, right: (end - el.time)*layerZoom}}
                start={start}
                end={end}
                onChange={(value) => onTimeChange(key, i, value)}
                onSelect={() => onSelect(key, i)}
                selected={selectedTimestamp(key, i)}
                item={el}
                key={i}
              />
              }) : null}</div>
          })}
      </div> : null}
    </div>
  );
};
