import React, { useRef, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setClassParam } from "../../../../../slices/three-slice";
import { onDrag } from "./Controls";
import * as THREE from 'three'
import { objectAnimation } from "../Functions";
import { useThree } from "@react-three/fiber";
import { degrees_to_radians } from "../../../../Common/Functions";

const Image = ({ item }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [ratio, setRatio] = useState(1)
  const texture = useMemo(() => new THREE.TextureLoader().load( item.image, (t) => {
    setRatio(t.image.naturalWidth/t.image.naturalHeight)
  }), [item.image])

  const bindDrag = onDrag(ref.current, item, onDragEnd);
  objectAnimation(item, ref.current)

  function onDragEnd(positions) {
    let { x, y, z } = positions;
    dispatch(
      setClassParam({ param: "position", value: {x, y, z} })
    );
  }

  return (
    <sprite
      scale={[ratio * item.scale,  item.scale, 1]}
      position={[item.position.x, item.position.y, item.position.z]}
      {...bindDrag()}
      ref={ref}
      name={`${item.class}-${item.id}`}
      userData={{ratio, ready: texture ? true : false}}
      frustumCulled={false}
    >
      <spriteMaterial
        transparent
        rotation={degrees_to_radians(item.rotation)}
        opacity={item.opacity}
        attach="material"
        sizeAttenuation={false}
        map={texture}
        onUpdate={(self) => self.needsUpdate = true}
      />
    </sprite>
  );
};

const Images = () => {
  let images = useSelector((state) => state.ThreeSlice.present.images);

  return images.map((item, index) => {
    if(Object.keys(item).length){
      return <Image item={item} key={index} />;
    }
  });
};

export default Images;
