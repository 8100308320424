import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setClassParam } from "../../../../../../slices/three-slice";
import GroupedSetting from "../../../../../Common/GroupedSetting/GroupedSetting";
import Slider from "../../../../../Common/Slider/Slider";
import { connectSliders } from "../Functions";
import * as styles from "./camera.module.scss";

const CameraTab = ({ timestamp }) => {
  const dispatch = useDispatch();
  const scene = useSelector((state) => state.ThreeSlice.present.scene);

  const ref = scene?.getObjectByName(`camera`);

  let sliderData = [
    {
      id: `camera-zoom`,
      path: "zoom",
    },
    {
      id: `camera-positionX`,
      path: "position.x",
    },
    {
      id: `camera-positionY`,
      path: "position.y",
    },
    {
      id: `camera-positionZ`,
      path: "position.z",
    },
  ];
  connectSliders(sliderData, ref);

  function changeParam(param, value, axis) {
    if (param === "position") {
      value = { ...ref.position, [axis]: value };
    }
    dispatch(setClassParam({ param, value }));
  }

  function onChange(param, value, axis) {
    if (param === "position") {
      let newPosition = { ...ref.position, [axis]: value };
      let { x, y, z } = newPosition;
      ref.position.set(x, y, z);
      ref.lookAt(0, 0, 0);
    } else if (param === "zoom") {
      ref.zoom = value;
      ref.updateProjectionMatrix();
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <span className={styles.title}>Camera</span>
        <div className={styles.settings}>
          <div className={styles.row}>
            <span>Zoom</span>
            <Slider
              id={`camera-zoom`}
              min={0}
              max={5}
              step={0.001}
              onEnd={(value) => changeParam("zoom", value)}
              onChange={(value) => onChange("zoom", value)}
            />
          </div>
          <div className={styles.row}>
            <span>Position X</span>
            <Slider
              id={`camera-positionX`}
              min={-100}
              max={100}
              step={0.001}
              onEnd={(value) => changeParam("position", value, "x")}
              onChange={(value) => onChange("position", value, "x")}
            />
          </div>
          <div className={styles.row}>
            <span>Position Y</span>

            <Slider
              id={`camera-positionY`}
              min={-100}
              max={100}
              step={0.001}
              onEnd={(value) => changeParam("position", value, "y")}
              onChange={(value) => onChange("position", value, "y")}
            />
          </div>
          <div className={styles.row}>
            <span>Position Z</span>
            <Slider
              id={`camera-positionZ`}
              min={-100}
              max={100}
              step={0.001}
              onEnd={(value) => changeParam("position", value, "z")}
              onChange={(value) => onChange("position", value, "z")}
            />
          </div>
          <div>
            <span className={styles.note}>
              Camera rotates around the origin*
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraTab;
