import React, { useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import {
  Provider,
  ReactReduxContext,
  useDispatch,
  useSelector,
} from "react-redux";
import Devices from "./CanvasComponents/Devices/Devices";
import Lights from "./CanvasComponents/Lights";
import Controls from "./CanvasComponents/Controls";
import useResizeObserver from '@react-hook/resize-observer'
import Camera from "./CanvasComponents/Camera";
import Shadows from './CanvasComponents/Shadows'
import Assets from "./CanvasComponents/Assets";
import * as THREE from 'three'
import Background from "./CanvasComponents/Background";
import { Suspense } from "react";
import { setCurrentItem, setScene } from "../../../../slices/three-slice";
import VideoCapture from "./CanvasComponents/VideoCapture";
import Transformer from "./CanvasComponents/Transformer/Transformer";
import { updateCanvasDimensions } from "../../../Common/Functions";
import Images from "./CanvasComponents/Images";
import { ResizeObserver } from "@juggle/resize-observer";
import { InnerModals } from "../Modals/Modals";

// (function(){var script=document.createElement('script');script.onload=function(){var stats=new Stats();document.body.appendChild(stats.dom);requestAnimationFrame(function loop(){stats.update();requestAnimationFrame(loop)});};script.src='//mrdoob.github.io/stats.js/build/stats.min.js';document.head.appendChild(script);})()

const ThreeCanvas = () => {
  const dispatch = useDispatch()
  const dimensions = useSelector(state => state.ThreeSlice.present.dimensions)

  const [canvasDimensions, setCanvasDimensions] = useState({})

  useEffect(() => {
    // Update canvas size on screen resize
    async function fetchDimensions() {
      let canvasSize = await updateCanvasDimensions(dimensions);
      setCanvasDimensions(canvasSize)
    }
    fetchDimensions()

    // Set observer
    let parent = document.getElementById("stage-parent");
    const resizeObserver = new ResizeObserver(fetchDimensions);
    resizeObserver.observe(parent);

    return () => {
      resizeObserver.disconnect();
    };
  }, [dimensions])

  return (
    <div style={{height: '100%', width: '100%'}} id={'stage-parent'}>
      <InnerModals />
    <ReactReduxContext.Consumer>
      {({ store }) => (
        <Canvas 
        resize={{ polyfill: ResizeObserver }}
        style={{width: canvasDimensions.width, height: canvasDimensions.height, margin: 'auto', boxShadow: 'rgb(14 19 24 / 7%) 0px 2px 8px'}}
        concurrent={true}
        dpr={typeof window !== 'undefined' ? window.devicePixelRatio : 1}
        gl={{
          preserveDrawingBuffer: true,
          alpha: true,
          antialias: true,
        }}
        onPointerMissed={() => dispatch(setCurrentItem({class: 'camera'}))}
        onCreated={({ gl, scene }) => {
          dispatch(setScene(scene))
          gl.domElement.id = 'main-canvas'
          gl.toneMapping = THREE.NoToneMapping;
          // gl.physicallyCorrectLights = true;
          // gl.outputEncoding = THREE.sRGBEncoding;
        }}>
          <Provider store={store}>
            <VideoCapture />
            <Background />
            <Transformer />
            <Lights />
            <Camera />
            <Controls />
            <Devices />
            <Assets />
            <Images />
            <Shadows />
          </Provider>
        </Canvas>
      )}
    </ReactReduxContext.Consumer>
    </div>
  );
};

export default ThreeCanvas;
