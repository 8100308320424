import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { useFrame, useThree } from "@react-three/fiber";
import { saveAs } from "file-saver";
import { useEffect, useRef, useState } from "react";
import { isIE  } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../../../../configureStore";
import API from "../../../../../firebase/api";
import { setCurrentTime, setErrorModal, setRecording } from "../../../../../slices/three-slice";
import { dataURItoBlob } from "../../../../Common/Functions";
import * as THREE from 'three'

const ffmpeg = createFFmpeg({log: false});




const VideoCapture = () => {
    const [ready, setReady] = useState(false)
    const format = useSelector(state => state.ThreeSlice.present.recording.format)
    const recording = useSelector(state => state.ThreeSlice.present.recording.status)
    const duration = useSelector(state => state.ThreeSlice.present.duration)
    const devices = useSelector(state => state.ThreeSlice.present.devices)
    const { invalidate, gl, camera, set } = useThree();
    let dispatch = useDispatch();

    let numberOfFrames = useRef(0)
    let allowNext = useRef(true)

    async function waitOnVideosToSeek(){
      var promiseList = []
      for(let i=0; i < devices.length; i++){
        let video = devices[i].videoRef
        if(video){
          if(video.seeking){
            let promise = new Promise((resolve, reject) => {
              video.addEventListener('seeked', resolve, {once: true})
            })
            promiseList.push(promise)
          }
        }
      }
      return await Promise.allSettled(promiseList)
    }

    function toEven(number){
        return 2 * Math.round(number / 2);
    }
    
    useEffect(() => {
      if(format === 'png'){
          // Download image
          let dimensions = store.getState().ThreeSlice.present.dimensions
          let {scale, free} = store.getState().ThreeSlice.present.recording.info
          // gl.setSize(dimensions.width * scale, dimensions.height * scale, false)
          // let ratio = (dimensions.width / (parseFloat(gl.domElement.style.width))) 
          let ratio = gl.getPixelRatio()
          let size = {width: dimensions.width * scale / ratio, height: dimensions.height * scale / ratio}
          gl.setSize(size.width, size.height, false)
          // // gl.setViewport(0, 0, size.width/2, size.height/2);
          // // gl.setPixelRatio(2 * scale * ratio)
          // // gl.domElement.width = dimensions.width * scale
          // // gl.domElement.height = dimensions.height * scale

          setTimeout(() => {
            let canvasData = gl.domElement.toDataURL();
            let blob = dataURItoBlob(canvasData)
            saveAs(blob, 'previewed.png')
            gl.setSize(gl.domElement.offsetWidth, gl.domElement.offsetHeight, false)
            API.onUserDownload('3d', free)
            // gl.setPixelRatio(window.devicePixelRatio)
            dispatch(
              setRecording({param: 'format', value: null})
            );
          }, 500)
      }
      else if(format === 'webm' || format === 'mp4'){
        if(isIE){
          dispatch(setErrorModal(true))
          dispatch(
            setRecording({param: 'format', value: null})
          );
        }
        else{
          dispatch(
            setRecording({param: 'status', value: true})
          );
        }
      }
    }, [format])

  useEffect(() => {
    if(recording){
      async function initFFMPEG(){
        // Check that inputs are not more than 2GB
        // let snapshot = gl.domElement.toDataURL()
        // let head = 'data:image/png;base64,';
        // var imgFileSize = Math.round((snapshot.length - head.length)*3/4) ;
        // let totalBytes = imgFileSize * totalDuration * 30
        // let totalGB = totalBytes / (1024**3)
        // if(totalGB >= 1.8){
        //   toast.error('The video is too large. Try reducing dimensions or duration.')
        //   return
        // }
        dispatch(
          setRecording({param: 'progress', value: 0})
        );
        dispatch(
          setRecording({param: 'text', value: 'Initializing your animation...'})
        );
        if(!ffmpeg.isLoaded()){
          await ffmpeg.load()
          ffmpeg.setProgress(({ ratio }) => {
            dispatch(
              setRecording({param: 'progress', value: Math.floor(ratio*100)})
            );
          });
        }

        let dimensions = store.getState().ThreeSlice.present.dimensions
        let scale = store.getState().ThreeSlice.present.recording.info.scale
        // gl.setSize(dimensions.width*scale, dimensions.height*scale, false)
        // let dimensionWidth = store.getState().ThreeSlice.present.dimensions.width
        let ratio = gl.getPixelRatio()
        let size = {width: dimensions.width * scale / ratio, height: dimensions.height * scale / ratio}
          gl.setSize(size.width, size.height, false)
        // let ratio = (dimensions.width / (parseFloat(gl.domElement.style.width))) 

        // gl.setPixelRatio(window.devicePixelRatio * scale * ratio)
        // gl.domElement.width = dimensions.width * scale
        // gl.domElement.height = dimensions.height * scale
  
    //   gl.domElement.style.pointerEvents = "none";
    //   document.body.style.pointerEvents = "none";
    //     dispatch(startRecording())
        set({frameloop: 'demand'})
        setReady(true)
      }
      initFFMPEG()
    }
    else{
        set({frameloop: 'always'})
        setReady(false)
        // gl.setPixelRatio(window.devicePixelRatio)
        gl.setSize(gl.domElement.offsetWidth, gl.domElement.offsetHeight, false)
        dispatch(
          setRecording({param: 'format', value: null})
        );
    }
  }, [gl, recording, ffmpeg])

useEffect(() => {
    if(ready) invalidate()
}, [ready])
  useFrame(async (state, delta) => {
    if (ready && state.frameloop === 'demand' && allowNext.current) {
      allowNext.current = false
      var { scene, camera, gl } = state
      var currentTime = store.getState().ThreeSlice.present.currentTime

      if (currentTime > duration) {
        // running.current = true
        dispatch(
          setRecording({param: 'text', value: 'Rendering your animation...'})
        );
        // Get dimensions
        var dimensions = store.getState().ThreeSlice.present.dimensions
        let scale = store.getState().ThreeSlice.present.recording.info.scale
        let width = toEven(dimensions.width * scale)
        let height = toEven(dimensions.height * scale)
        let ffmpegDimensions = `${width}x${height}`

        if(format === 'webm'){
          await ffmpeg.run('-framerate', '60', '-pattern_type', 'glob', '-i', '*.png', '-vf', 'premultiply=inplace=1',  '-c:v', 'libvpx-vp9', '-lossless', '1', '-pix_fmt', 'yuva420p', '-deadline', 'best', '-s', ffmpegDimensions, 'out.webm');
        }
        else{
          await ffmpeg.run('-framerate', '60', '-pattern_type', 'glob', '-i', '*.png', '-c:v', 'libx264', '-pix_fmt', 'yuv420p', '-preset', 'ultrafast', '-s', ffmpegDimensions, 'out.mp4');
        }

        const data = ffmpeg.FS('readFile', `out.${format}`);

        let blob = new Blob([data.buffer])
        if(blob.size > 100){
          saveAs(blob, `out.${format}`);
          let free = store.getState().ThreeSlice.present.recording.info.free
          API.onUserDownload('3d', free)
        }
        
        ffmpeg.FS('unlink', `out.${format}`);

        for (let i = 0; i < numberOfFrames.current; i ++) {
          const num = `00000${i}`.slice(-6);
          ffmpeg.FS('unlink', `tmp.${num}.png`);
        }

        dispatch(setRecording({param: 'status', value: false}));
        // running.current = false
        // currentTime.current = 0;
        allowNext.current = true
        numberOfFrames.current = 0
        // gl.setPixelRatio(window.devicePixelRatio);

        // gl.domElement.style.pointerEvents = "auto";
        // document.body.style.pointerEvents = "auto";
        
      } else {
          await waitOnVideosToSeek()
          gl.render(scene, camera)

          let data = await fetchFile(gl.domElement.toDataURL());
          const num = `00000${numberOfFrames.current}`.slice(-6);
          // use ffmpeg
          ffmpeg.FS('writeFile', `tmp.${num}.png`, data);
          let newTime = currentTime + 100/6
          dispatch(setCurrentTime(newTime))
          dispatch(
            setRecording({param: 'progress', value: Math.floor(newTime*100/duration)})
          );

          numberOfFrames.current++;
          allowNext.current = true
          invalidate();
      }
    }
  }, recording ? 1 : 0);

  return null;
};

export default VideoCapture;
