import React from 'react'
import * as styles from './inlineoptions.module.scss'

const InlineOptions = ({active, options, onChange}) => {
    function onClick(value){
        if(value !== active){
            onChange(value)
        }
    }

    return(
        <div className={styles.container}>
            {options.map(({display, value}) => {
                return <span className={active === value ? styles.active : undefined} onClick={() => onClick(value)} key={value}>{display}</span>
            })}
        </div>
    )
}

export default InlineOptions;