import useResizeObserver from '@react-hook/resize-observer'
import React, {useEffect, useState } from 'react'
import ThreeCanvas from '../../components/Makers/3D-new/Canvas/Canvas'
import Editor from '../../components/Makers/3D-new/Editor/Editor'
import Header from '../../components/Makers/3D-new/Header/Header'
import VideoComponent from '../../components/Makers/3D-new/VideoComponent/VideoComponent'
import Modals from '../../components/Makers/3D-new/Modals/Modals'
import { container } from '../../styles/testing.module.scss'
import { useSelector } from 'react-redux'
export default () => {
    const animated = useSelector((state) => state.ThreeSlice.present.animated);
    const [observe, setObserve] = useState(null)
    const [toolHeight, setToolHeight] = useState(1)
    const size = useResizeObserver(observe, (e) => setToolHeight(`calc(100vh - ${e.contentRect.height + 41}px)`))
  
  
    useEffect(() => {
      setObserve(document.getElementById('video-component'))
    }, [animated])
    return(
        <div className={container}>
            <Header />
            <div style={{display: 'flex', height: animated ? toolHeight : 'calc(100% - 41px)'}}>
                <Editor />
                <div style={{padding: 30, width: '100%'}}>
                    <ThreeCanvas />
                </div>
            </div>
            {animated ? <VideoComponent /> : null}
            <Modals />
        </div>
    )
}