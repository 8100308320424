import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleObjectAnimation } from "../../../../../slices/three-slice";
import LayerName from "./Name/Name";
import * as styles from "./names.module.scss";

const LayerNames = ({ list }) => {
  const dispatch = useDispatch()
  const currentItem = useSelector((state) => state.ThreeSlice.present.currentItem);
  const ref = useRef()

  useEffect(() => {
    if(ref.current){
        let layersDiv = document.getElementById('right-video-component')
        let timer
        var nameScrolling = false;
        var layerScrolling = false;
        function setScroll(e){
            if(!nameScrolling){
              layerScrolling = true
              ref.current.scrollTop = layersDiv.scrollTop
              ref.current.scrollLeft = layersDiv.scrollLeft
              
              clearTimeout( timer );
              timer = setTimeout(() => {
                layerScrolling = false
              }, 50)
            }
        }

        function reverseScroll(e){
          if(!layerScrolling){
            nameScrolling = true
            layersDiv.scrollTop = ref.current.scrollTop
            layersDiv.scrollLeft = ref.current.scrollLeft
            
            clearTimeout( timer );
            timer = setTimeout(() => {
              nameScrolling = false
            }, 50)
          }


        }
        layersDiv.addEventListener('scroll', setScroll)
        ref.current.addEventListener('scroll', reverseScroll)

        return () => {
            layersDiv?.removeEventListener('scroll', setScroll)
            ref.current?.removeEventListener('scroll', reverseScroll)
        }
    }
}, [])

  function changeAnimation(item, animation){
    dispatch(toggleObjectAnimation({id: item.id, class: item.class, animation}))
  }

  return (
    <div className={styles.container}>
      <span className={styles.title}>Components</span>
      <div className={styles.list} ref={ref}>
        {list.map((item, index) => {
          if (Object.keys(item).length) {
            return <LayerName active={currentItem.class === item.class && currentItem.id === item.id} item={item} key={index} changeAnimation={(animation) => changeAnimation(item, animation)}/>;
          }
        })}
      </div>

    </div>
  );
};

export default LayerNames;
