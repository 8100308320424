/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: vnvisual.com (https://sketchfab.com/bee3dd)
license: SKETCHFAB Standard (https://sketchfab.com/licenses)
source: https://sketchfab.com/3d-models/apple-ipad-pro-11-2020-ea18681ddd684f49a2ad7e6be43cc71d
title: Apple iPad Pro 11 2020
*/

import React, { useEffect, useMemo, useRef } from 'react'
import * as THREE from 'three'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/3DModels/Tablets/ipad/scene.gltf')

  const colorMaterials = useMemo(() => {
    return ['Steel_Body', 'Camera_Gloss_2'].reduce((acc, key) => {
        acc[key] = materials[key].clone()
        return acc
    }, {})
  },[materials])

  const display = useMemo(() => materials.Screen.clone(), [materials])

  useEffect(() => {
    if(group.current){
      props.init({ref: group.current, colorMaterials: Object.values(colorMaterials), display})
    }
  }, [])

  const center = useMemo(() => {
    const box = new THREE.Box3().setFromObject(nodes.OSG_Scene);
    return box.getCenter(new THREE.Vector3())
  }, [nodes.OSG_Scene])

  return (
    <group ref={group} {...props}>
      <group scale={[0.01,0.01,0.01]}>
      <group rotation={[-Math.PI / 2, 0, 0]} position={[-center.x, -center.y, -center.z]}>
        <mesh geometry={nodes.mesh_0.geometry} material={materials.Anten} />
        <mesh geometry={nodes.mesh_1.geometry} material={materials.Apple_Logo} />
        <mesh geometry={nodes.mesh_2.geometry} material={materials.Camera_Gloss_2} />
        <mesh geometry={nodes.mesh_3.geometry} material={materials.Camera_Static} />
        <mesh geometry={nodes.mesh_4.geometry} material={materials.Charge} />
        <mesh geometry={nodes.mesh_5.geometry} material={materials.Chrome} />
        <mesh geometry={nodes.mesh_6.geometry} material={materials.Crystal} />
        <mesh geometry={nodes.mesh_7.geometry} material={materials.Flash_Orange} />
        <mesh geometry={nodes.mesh_8.geometry} material={materials.Flash_Yellow} />
        <mesh geometry={nodes.mesh_9.geometry} material={materials.Front_cover} />
        <mesh geometry={nodes.mesh_10.geometry} material={materials.Gloss_Black} />
        <mesh geometry={nodes.mesh_11.geometry} material={materials.Matte_Black} />
        <mesh geometry={nodes.mesh_12.geometry} material={materials.Mesh_Speaker} />
        {/* <mesh geometry={nodes.mesh_13.geometry} material={materials.New_Pencil} /> */}
        <mesh geometry={nodes.mesh_14.geometry} material={display} />
        <mesh geometry={nodes.mesh_15.geometry} material={colorMaterials.Steel_Body} />
        <mesh geometry={nodes.mesh_16.geometry} material={colorMaterials.Steel_Body} />
        <mesh geometry={nodes.mesh_17.geometry} material={materials.Steel_Camera} />
        <mesh geometry={nodes.mesh_18.geometry} material={materials.White_Tran} />
        <mesh geometry={nodes.mesh_19.geometry} material={materials.Glass} />
      </group>
      </group>
    </group>
  )
}

useGLTF.preload('/3DModels/Tablets/ipad/scene.gltf')
