import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import Header from "./Header/Header";
import Layers from "./Layers/Layers";
import Names from "./Names/Names";
import Timeline from "./Timeline/Timeline";
import * as styles from "./videocomponent.module.scss";

const VideoComponent = () => {
  const [height, setHeight] = useState(400);
  

  const devices = useSelector((state) => state.ThreeSlice.present.devices);
  const texts = useSelector((state) => state.ThreeSlice.present.texts);
  const images = useSelector((state) => state.ThreeSlice.present.images);
  const camera = useSelector((state) => state.ThreeSlice.present.camera);
  const plane = useSelector((state) => state.ThreeSlice.present.plane);

  const layersList = useMemo(() => [camera, plane.active ? plane : {}, ...texts, ...images, ...devices], [
    devices,
    texts,
    images,
    camera,
    plane
  ]);

  return (
    <Rnd
      id={"video-component"}
      bounds={"window"}
      disableDragging={true}
      enableResizing={{ top: true }}
      className={styles.container}
      default={{ height: 230 }}
      style={{
        top: "auto",
        position: "fixed",
      }}
      minHeight="74"
      maxHeight="600"
      onResize={(e, dir, ref) => setHeight(ref.offsetHeight)}
    >
      <div style={{height: '100%'}}>
        <Header />
        <div className={styles.bottom}>
          <div className={styles.left}>
            <Names list={layersList} />
          </div>
          <div className={styles.right} id={'right-video-component'}>
            <Timeline height={height - 84} />
            <Layers list={layersList} />
          </div>
        </div>
      </div>
      {/* <Header />
      <div className={styles.middle}>
      <LayerZoom />
      <Timeline height={height}/>
      </div>
      <div className={styles.animationLayers}>
        <Names list={layersList}/>
        <Layers list={layersList}/>
      </div> */}
    </Rnd>
  );
};

export default VideoComponent;
