import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { store } from '../../../../../../configureStore'
import { setDimensions, setDimensionsModal } from '../../../../../slices/three-slice'
import Button from '../../../../Common/Button/Button'
import DraggableSetting from '../../../../Common/DraggableSetting/DraggableSetting'
import Modal from '../../../../Modals/Modal/Modal'
import * as styles from './dimensions.module.scss'

export default () => {
    const dispatch = useDispatch()
    const dimensions = useSelector(state => state.ThreeSlice.present.dimensions)
    const [width, setWidth] = useState(dimensions.width)
    const [height, setHeight] = useState(dimensions.height)

    function onChange(value, set){
        if(value <= 5000 && value >= 0 && Number.isInteger(parseInt(value))){
            set(parseInt(value))
        }
    }

    function onClose(){
        dispatch(setDimensionsModal(false))
        let updatedDimensions = store.getState().ThreeSlice.present.dimensions
        setTimeout(() => {
            setWidth(updatedDimensions.width)
            setHeight(updatedDimensions.height)
        }, 200)
    }

    function onSave(){
        dispatch(setDimensions({width, height}))
        onClose()
    }

    return(
        <DraggableSetting handle={'Dimensions'} position={{x: 10, y: 10}} show={dimensions.modal} onClose={onClose}>
            <div className={styles.container}>
                <div className={styles.inputs}>
                    <div>
                        <label for="width">Width</label>
                        <input min={0} max={"5000"} id={'width'} type={'number'} value={width} onChange={e => onChange(e.target.value, setWidth)}/>
                    </div>
                    <div>
                        <label for="height">Height</label>
                        <input min={0} max={5000} id={'height'} type={'number'} value={height} onChange={e => onChange(e.target.value, setHeight)}/>
                    </div>
                </div>
                <Button className={styles.button} onClick={onSave}>Save</Button>
            </div>
        </DraggableSetting>
    )
}