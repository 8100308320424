/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: RosstyArt (https://sketchfab.com/cholakov2020)
license: SKETCHFAB Editorial (https://sketchfab.com/licenses)
source: https://sketchfab.com/3d-models/apple-iphone-12-pro-pacific-blue-3ec1b0d7a683426eaaa9cc90e1d26114
title: Apple iPhone 12 Pro - Pacific blue
*/

import { useGLTF } from '@react-three/drei'
import React, { useEffect, useMemo, useRef } from 'react'
export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/3DModels/Phones/iphone_12/scene.gltf')

  const colorMaterials = useMemo(() => {
    return ['IPHONE_BACK', 'frame_metal', 'frame_metal_matte', 'REAR_CAM_MAIN', 'REAR_CAM_03'].reduce((acc, key) => {
        acc[key] = materials[key].clone()
        return acc
    }, {})
  },[materials])

  const display = useMemo(() => materials.display_wallpaper.clone(), [materials])

  useEffect(() => {
    if(group.current){
      props.init({ref: group.current, colorMaterials: Object.values(colorMaterials), display})
    }
  }, [])


  return (
    <group ref={group} {...props}>
      <group rotation={[0, -Math.PI / 2, 0]} scale={[20, 20, 20]}>
        <group rotation={[0, 0, 0]}>
          <group>
            <mesh material={materials.IPHONE_DISPLAY_FRAME} geometry={nodes.mesh_0.geometry} />
            {/* <mesh material={materials.TRANSPARENT_GLASS} geometry={nodes.mesh_1.geometry} /> */}
            <mesh material={colorMaterials.frame_metal} geometry={nodes.mesh_2.geometry} />
            <mesh material={materials.FRAME_MATTE} geometry={nodes.mesh_3.geometry} />
            <mesh material={materials.BLACK_MATTE} geometry={nodes.mesh_4.geometry} />
            <mesh material={materials.CHROME} geometry={nodes.mesh_5.geometry} />
            <mesh material={display} geometry={nodes.mesh_6.geometry} />
            <mesh material={colorMaterials.IPHONE_BACK} geometry={nodes.mesh_7.geometry} />
            <mesh material={colorMaterials.REAR_CAM_MAIN} geometry={nodes.mesh_8.geometry} />
            <mesh material={materials.IPHONE_LOGO} geometry={nodes.mesh_9.geometry} />
            <mesh material={colorMaterials.REAR_CAM_03} geometry={nodes.mesh_10.geometry} />
            <mesh material={materials.BLACK_GLOSSY} geometry={nodes.mesh_11.geometry} />
            <mesh material={materials.FRONT_CAM_TEXTURE} geometry={nodes.mesh_12.geometry} />
            <mesh material={materials.FRONT_SPEAKER} geometry={nodes.mesh_13.geometry} />
            <mesh material={colorMaterials.frame_metal_matte} geometry={nodes.mesh_14.geometry} />
            <mesh material={materials.camera_white_glass} geometry={nodes.mesh_15.geometry} />
            <mesh material={materials.camera_black_glass} geometry={nodes.mesh_16.geometry} />
            <mesh material={materials.camera_lens_texture} geometry={nodes.mesh_17.geometry} />
            <mesh material={materials.camera_lens_outside} geometry={nodes.mesh_18.geometry} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/3DModels/Phones/iphone_12/scene.gltf')
