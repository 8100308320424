import React, { useEffect, useRef } from 'react'
import { DraggableCore } from 'react-draggable'
import { useDispatch, useSelector } from 'react-redux'
import {setCurrentTime} from '../../../../../slices/three-slice'
import HandleIcon from '../../../../../assets/svg/3d-maker/drag-handle.svg'
import * as styles from './timeline.module.scss'

const Times = ({duration, zoom}) => {
    let width = duration * zoom
    let n = Math.ceil(width / 100)

    return [...new Array(n + 1)].map((item, index) => {
        return <span key={index} style={{left: (width * index)/n}}>{Math.round(((index * width) / n) / (zoom * 1000) * 10) / 10}s</span>
    })
}

const Timeline = ({height}) => {
    const ref = useRef()

    let dispatch = useDispatch()
    let layerZoom = useSelector((state) => state.ThreeSlice.present.layerZoom);
    let duration = useSelector(state => state.ThreeSlice.present.duration)
    let currentTime = useSelector(state => state.ThreeSlice.present.currentTime)

    function onDrag(e, {deltaX}){
        let newTime = currentTime + (deltaX / layerZoom)
        if(newTime < 0 || newTime > duration) return
        dispatch(setCurrentTime(newTime))
    }

    function onClick(e){
        var rect = ref.current.getBoundingClientRect();
        var x = e.clientX - rect.left; //x position within the element.
        let newTime = (x - 8) / layerZoom
        if(newTime < 0) newTime = 0
        else if(newTime > duration) newTime = duration
        dispatch(setCurrentTime(newTime))
    }

    return(
        <div className={styles.container}>
            <div style={{width: duration*layerZoom}} className={styles.inner} onClick={onClick} ref={ref}>
            <DraggableCore grid={[1, 1]} axis="x" onDrag={onDrag} >
                <div className={styles.handle} style={{transform: `translate3d(${currentTime*layerZoom}px,0,0)`}}>
                    <HandleIcon  />
                    <div className={styles.verticalLine} style={{height}} />
                </div>
            </DraggableCore>
            <div className={styles.times}>
                    <Times duration={duration} zoom={layerZoom} />
            </div>
            </div>
        </div>
    )
}

export default Timeline;