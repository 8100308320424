import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setClassParam } from "../../../../../slices/three-slice";
import { degrees_to_radians } from "../../../../Common/Functions";
import {
  createLottieAnimation,
  createLottieAnimationsCanvas,
  objectAnimation,
} from "../Functions";
import { onDrag } from "./Controls";

const Asset = ({ item, SK }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const { texture } = createLottieAnimation(ref.current, item, SK);
  const bindDrag = onDrag(ref.current, item, onDragEnd);

  objectAnimation(item, ref.current)

  function onDragEnd(positions) {
    let { x, y, z } = positions;
    dispatch(
      setClassParam({ param: "position", value: {x, y, z} })
    );
  }

  return (
    <sprite
      scale={[item.ratio * item.scale,  item.scale, 1]}
      position={[item.position.x, item.position.y, item.position.z]}
      {...bindDrag()}
      ref={ref}
      name={`${item.class}-${item.id}`}
      userData={{ratio: item.ratio, ready: texture ? true : false}}
      frustumCulled={false}
    >
      <spriteMaterial
        transparent
        rotation={degrees_to_radians(item.rotation)}
        opacity={item.opacity}
        attach="material"
        sizeAttenuation={false}
        map={texture}
        alphaTest={0.02}
        onUpdate={(self) => (self.needsUpdate = true)}
      />
    </sprite>
  );
};

const Assets = () => {
  const SK = createLottieAnimationsCanvas();
  let texts = useSelector((state) => state.ThreeSlice.present.texts);

  return texts.map((item, index) => {
    if(Object.keys(item).length){
      return <Asset item={item} SK={SK} key={index} />;
    }
  });
};

export default Assets;
