import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setClassParam } from '../../../../../../slices/three-slice'
import BackgroundPicker from '../../../../../Common/BackgroundPicker/BackgroundPicker'
import * as styles from './background.module.scss'

export default ({ }) => {
    const dispatch = useDispatch()
    const background = useSelector(state => state.ThreeSlice.present.background.value)
    const dimensions = useSelector(state => state.ThreeSlice.present.dimensions)

    return(
        <div>
            <h2>Background</h2>
            <BackgroundPicker background={background} dimensions={dimensions} setBackground={(value) => dispatch(setClassParam({param: 'value', value}))}/>
        </div>

    )
}