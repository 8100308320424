import React from 'react'
import { useSelector } from 'react-redux'
import Background from './Background/Background'
import Device from './Device/Device'
import Text from './Text/Text'
import Image from './Image/Image'
import Camera from './Camera/Camera'
import Plane from './Plane/Plane'

const CurrentTab = () => {
    const currentItem = useSelector(state => state.ThreeSlice.present.currentItem)

    return(
        <>
            {currentItem.class === 'background' ? 
            <Background />
            : currentItem.class === 'devices' ?
            <Device id={currentItem.id}  /> : 
            currentItem.class === 'texts' ?
            <Text id={currentItem.id} /> : 
            currentItem.class === 'images' ?
            <Image id={currentItem.id}/> :
            currentItem.class === 'camera' ?
            <Camera /> :
            currentItem.class === 'plane' ?
            <Plane /> :
            null}
        </>
    )
}

export default CurrentTab;