import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Toggle from 'react-toggle';
import { setClassParam } from '../../../../../../slices/three-slice';
import * as styles from './plane.module.scss'
import Slider from '../../../../../Common/Slider/Slider';

const PlaneTab = () => {
    const dispatch = useDispatch()

    const {name, height, opacity, blur, active} = useSelector(state => state.ThreeSlice.present.plane)

    function onChange(param, value){
        dispatch(setClassParam({param, value}))
    }

    return(
        <div className={styles.container}>
        <div className={styles.section}>
          <span className={styles.title}>Plane</span>
          <div className={styles.settings}>
          <div className={styles.row}>
          <span>Visible</span>
            <Toggle checked={active} onChange={e => onChange('active', e.target.checked)} />
            </div>
            <div className={styles.row}>
          <span>Height</span>
          <Slider
                min={-10}
                max={10}
                step={0.001}
                value={height}
                onChange={(value) => onChange("height", value)}
                />
            </div>
            <div className={styles.row}>
          <span>Blur</span>
          <Slider
                min={0}
                max={15}
                step={0.001}
                value={blur}
                onChange={(value) => onChange("blur", value)}
                />
            </div>
            <div className={styles.row}>
          <span>Opacity</span>
          <Slider
                min={0}
                max={1}
                step={0.001}
                value={opacity}
                onChange={(value) => onChange("opacity", value)}
                />
            </div>
        </div>
        </div>
        </div>
    )
}

export default PlaneTab;