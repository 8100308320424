import React from 'react'
import Dropdown from '../../../../Common/Dropdown/Dropdown'
import HamburgerIcon from '../../../../../assets/svg/3d-maker/header/hamburger.svg'
import UndoIcon from '../../../../../assets/svg/undo.svg'
import RedoIcon from '../../../../../assets/svg/redo.svg'
import * as styles from './file.module.scss'
import { useDispatch } from 'react-redux'
import { resetCanvas, toggleRotate, setDimensionsModal, setEditor } from '../../../../../slices/three-slice'
import { navigate } from 'gatsby-link'
import { saveAs } from "file-saver";
import { store } from '../../../../../../configureStore'
import Firebase from '../../../../../firebase/index'
import API from '../../../../../firebase/api'
import { toast } from 'react-toastify'
import { userLoggedIn } from '../../../../Common/Functions'
import { setLoader } from '../../../../../slices/user-slice'

const Icon = () => {
    return(
        <div className={styles.container}>
            <HamburgerIcon />
        </div>
    )
}

export default () => {
    const dispatch = useDispatch()

    async function save(){
        if(!userLoggedIn()){
            return
        }
        dispatch(setLoader(true))
        let canvas = document.getElementById('main-canvas')
        let thumbnail = canvas.toDataURL()

        let props = ['devices', 'images', 'texts', 'camera', 'background', 'uuid', 'plane', 'dimensions']
        let slice = store.getState().ThreeSlice.present
        let data = props.reduce((acc, prop) => {
            acc[prop] = slice[prop]
            return acc
        }, {})

        let response = await Firebase.saveTemplateToStorage(data, thumbnail)
        if(response){
            // create tags based on devices used in template
            let tags = [...new Set( data.devices.map(device => device.frame).filter(x => x)) ];
            // Check if there are any animations present
            let animated = [...data.devices, ...data.images, ...data.texts, data.camera].some((item) => {
                if(item.animations){
                return Object.keys(item.animations).some((key) => item.animations[key].length)
                }
                return false
            })
            await API.saveNewTemplateUser(data.uuid, {name: slice.name, type: '3d', thumbnail: `${data.uuid}.png`, animated, version_id: 2, tags, original_template_id: slice.originalTemplateId})
            toast.success('Saved')
        }
        else {
            toast.error('Something went wrong...')
        }
        dispatch(setLoader(false))
    }

    React.useEffect(() => {
        function editState(e){
            if(e.keyCode == 90 && (e.ctrlKey || e.metaKey)){
                dispatch({type: 'UNDO'})
            }
            else if(e.keyCode == 89 && (e.ctrlKey || e.metaKey)){
                dispatch({type: 'REDO'})
            }
        }
        window.addEventListener('keydown', editState)
        return () => {
          window.removeEventListener('keydown', editState)
        }
    }, [])

    const options = [
        {
            name: 'My Templates',
            onClick: () => window.location = '/templates'
        },
        {
            name: 'New',
            onClick: () => dispatch(resetCanvas())
        },
        {
            name: 'Edit',
            options: [
                {
                    name: "Undo",
                    onClick: () => dispatch({type: 'UNDO'}),
                    icon: <UndoIcon />,
                },
                {
                    name: "Redo",
                    onClick: () => dispatch({type: 'REDO'}),
                    icon: <RedoIcon />
                },
            ]
        },
        {
            name: 'Save',
            onClick: save
        },
        {
            name: 'Dimensions',
            onClick: () => dispatch(setDimensionsModal(true))
        }
    ]
    return(
        <Dropdown parent={<Icon />} options={options} />
    )
}