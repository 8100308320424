import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { store } from '../../../../../configureStore';
import ChromeModal from '../../../Modals/ChromeModal/ChromeModal';

const Loader = () => {
    const scene = useSelector(state => state.ThreeSlice.present.scene)
    const [ready, setReady] = useState(false)

    useEffect(() => {
        function checkProgress(){
                let {texts, images, devices} = store.getState().ThreeSlice.present
                let total = [...texts, ...images, ...devices].filter((x) => Object.keys(x).length > 0).length
                let totalReady = scene.children.filter(child => child.userData?.ready === true).length
                if(total === totalReady){
                    setReady(true)
                }
                else{
                    setTimeout(() => checkProgress(), 500)
                }
            }
            if(scene){
                checkProgress()
            }
    }, [scene])
    return(
        <>
        <div style={{
            position: 'absolute',
            left: 0,
            top:0,
            right:0,
            bottom:0,
            display: ready ? 'none' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'black',
            zIndex: 100,
        }}>
            <span style={{
                color: 'white',
                fontSize: '1.5rem'
            }}>Loading...</span>
        </div>
        {ready ? <ChromeModal /> : null}
        </>
    )
}

export default Loader;