import React, { useEffect, useMemo, useState } from 'react'
import ArrowIcon from '../../../../../../assets/svg/3d-maker/triangle-right.svg'
import TimerIcon from '../../../../../../assets/svg/3d-maker/stopwatch.svg'
import TextIcon from '../../../../../../assets/svg/3d-maker/header/text.svg'
import DeviceIcon from '../../../../../../assets/svg/3d-maker/header/cube.svg'
import CameraIcon from '../../../../../../assets/svg/3d-maker/header/camera.svg'
import ImageIcon from '../../../../../../assets/svg/3d-maker/header/image.svg'
import PlaneIcon from '../../../../../../assets/svg/3d-maker/header/square.svg'
import DeleteIcon from '../../../../../../assets/svg/bin.svg'
import * as styles from './name.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTimestamp, setCurrentItem, setSpecificClassParam } from '../../../../../../slices/three-slice'
import Easing from './Easing/Easing'

const LayerName = ({active, item, changeAnimation}) => {
    const dispatch = useDispatch()
    let currentTimestamp = useSelector((state) => state.ThreeSlice.present.currentTimestamp);

    const {name, animations, animationTool} = item
    function showDelete(key){
        if(currentTimestamp.class === item.class && currentTimestamp.id === item.id && currentTimestamp.key === key){
            return true
        }
        return false
    }

    function onClick(e){
        e.stopPropagation()
        dispatch(setSpecificClassParam({class: item.class, id: item.id, value: {animationTool: !animationTool}}))
    }

    const Icon = () => {
        let type = item.class
        if(type === 'texts'){
            return <TextIcon fill={'#ff9800'} />
        }
        else if(type === 'devices'){
            return <DeviceIcon fill={'#009688'} />
        }
        else if(type === 'camera'){
            return <CameraIcon fill={'#8e7dbe'} />
        }
        else if(type === 'images'){
            return <ImageIcon fill={'#ff6287'} />
        }
        else if(type === 'plane'){
            return <PlaneIcon fill={'#795548'} />
        }
        return null
    }
    return(
        <div className={`${styles.container} ${animationTool ? styles.open : undefined} ${active ? styles.active : undefined}`} onClick={() => dispatch(setCurrentItem({ class: item.class, id: item.id }))}>
            <div className={styles.main}>
                <Icon />
                {animations ? <ArrowIcon className={styles.arrow} style={{transform: animationTool ? 'rotateZ(90deg)' : null}} onClick={onClick}/> : null}
                <div className={styles.name} style={{marginLeft: animations ? 0 : '1.0rem'}}>{name}</div>
            </div>
            {animationTool ?
            <div className={styles.animations}>
                {Object.keys(animations).map((key) => {
                    return (<div key={key}>
                                <div>
                                <TimerIcon fill={animations[key].length ? '#2196f3' : '#7a7a7a'} onClick={(e) => {
                                    e.stopPropagation()
                                    changeAnimation(key)
                                }}/> 
                                <span>{key}</span> 
                                </div>
                                {showDelete(key) ?
                                <div className={styles.options}>
                                    {animations[key][currentTimestamp.index] ? <Easing currentEase={animations[key][currentTimestamp.index].easing}/> : null}
                                    <DeleteIcon className={styles.deleteIcon} onClick={() => dispatch(deleteTimestamp())} />
                                </div> : null}
                            </div>)
                })}
            </div> : null}
        </div>
    )
}

export default LayerName;