import React, { useEffect, useRef } from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { useDispatch, useSelector } from "react-redux";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { useGesture } from "react-use-gesture";
import * as THREE from "three";
import { setClassParam, setCurrentTime, setDragging, setOrbiting, togglePlay } from "../../../../../slices/three-slice";
import { store } from "../../../../../../configureStore";
import { toast } from "react-toastify";

extend({ OrbitControls });

function Controls() {
  const orbiting = useRef(false)
  const ref = useRef();

  const dispatch = useDispatch();
  const currentItem = useSelector((state) => state.ThreeSlice.present.currentItem);

  const enabled = currentItem.class === 'camera'


  const playing = useSelector(state => state.ThreeSlice.present.playing)
  const duration = useSelector(state => state.ThreeSlice.present.duration)
  
  const {
    gl: { domElement },
    camera,
  } = useThree();

  useFrame((state, delta) => {
    if(playing){
      let currentTime = store.getState().ThreeSlice.present.currentTime
      dispatch(setCurrentTime(currentTime + delta * 1000))
      if(currentTime >= duration){
        dispatch(togglePlay())
        dispatch(setCurrentTime(duration))
      }
    }
  })

  useGesture(
    {
      onWheelStart: () => {

      },
      onWheel: ({ event: { deltaY }, last }) => {
        if (last){
          if(!orbiting.current){
            dispatch(
              setClassParam({
                item: { class: "camera" },
                param: "zoom",
                value: camera.zoom,
              })
            );
          }
          return
        }

        if (deltaY < 0) {
          // Zoom in
          camera.zoom *= 1.05;
        } else if (deltaY > 0) {
          // Zoom out
          camera.zoom *= 0.95;
        }
        camera.updateProjectionMatrix();
      },
    },
    { domTarget: domElement, enabled }
  );

  useEffect(() => {
    function onStart(){
      orbiting.current = true
    }
    function onEnd(e) {
      // save position
      let { x, y, z } = camera.position;
      dispatch(
        setClassParam({
          item: { class: "camera" },
          param: "position",
          value: { x, y, z },
        })
      );
      dispatch(
        setClassParam({
          item: { class: "camera" },
          param: "zoom",
          value: camera.zoom,
        })
      );
      orbiting.current = false
    }

    if (ref.current) {
      ref.current.addEventListener("start", onStart, true);
      ref.current.addEventListener("end", onEnd, true);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("start", onStart);
        ref.current.removeEventListener("end", onEnd);
      }
    };
  }, [ref.current]);

  return (
    <orbitControls
      ref={ref}
      args={[camera, domElement]}
      enableZoom={false}
      enablePan={false}
      enabled={enabled}
    />
  );
}

export function onDrag(object, item, onEnd) {
  const dispatch = useDispatch();
  const data = useRef({
    plane: new THREE.Plane(),
    worldPosition: new THREE.Vector3(),
    intersection: new THREE.Vector3(),
    offset: new THREE.Vector3(),
    inverseMatrix: new THREE.Matrix4(),
    allowDrag: false,
  });

  let {
    plane,
    worldPosition,
    intersection,
    offset,
    inverseMatrix,
    allowDrag
  } = data.current;

  const { camera, raycaster, scene } = useThree();


  return useGesture({
    onDragStart: () => {
      const currentItem = store.getState().ThreeSlice.present.currentItem
      allowDrag = item.class === currentItem.class && item.id === currentItem.id

      if(currentItem.class === 'devices' && currentItem.id !== undefined && store.getState().ThreeSlice.present.devices[currentItem.id].mode === 'rotate'){
        allowDrag = false
      }

      if(currentItem.class !== 'camera'){
        let currentObject = scene.getObjectByName(`${currentItem.class}-${currentItem.id}`)
        let intersectsObject = currentObject ? raycaster.intersectObject(currentObject, true) : false
  
        // If the object is current item
        if(allowDrag){
            // dispatch(setDragging(true));
            plane.setFromNormalAndCoplanarPoint(
              camera.getWorldDirection(plane.normal),
              worldPosition.setFromMatrixPosition(object.matrixWorld)
            );
            raycaster.ray.intersectPlane(plane, intersection);
            inverseMatrix.copy(object.parent.matrixWorld).invert();
            offset
              .copy(intersection)
              .sub(worldPosition.setFromMatrixPosition(object.matrixWorld));
        }
        else if(!intersectsObject?.length){
            toast.error('To edit this, select it in the "Components" tab.')
        }
      }
    },
    onDrag: ({ first, last }) => {
      if(allowDrag){
      // Move object along the plane
      raycaster.ray.intersectPlane(plane, intersection);
      object.position.copy(
        intersection.sub(offset).applyMatrix4(inverseMatrix)
      );
      }
    },
    onDragEnd: () => {
      if(allowDrag){
        let { x, y, z } = object.position;
        onEnd({ x, y, z });
        // dispatch(setDragging(false));
        allowDrag = false
      }
    },
  });
}

export default Controls;
