import React, { useRef, useMemo, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/3DModels/Tablets/ipad_mini_6/scene.gltf')

  const colorMaterials = useMemo(() => {
    const list = ['back_panel01','mat_purple_dark','back_panel_top_button_part_01',
        'back_panel_top_button01','mini_usb01','mini_usb_part_03'
    ]

    return list.reduce((acc, name) => {
      let material =  nodes[name]?.material || materials[name]
      acc[name] = material.clone()
      return acc
  }, {})
},[nodes, materials])

  const display = useMemo(() => materials.screen.clone(), [materials])

  useEffect(() => {
    if(group.current){
      props.init({ref: group.current, colorMaterials: Object.values(colorMaterials), display})
    }
  }, [])

  return (
    <group ref={group} {...props}>
        <group rotation={[0, -Math.PI, 0]} scale={[0.18, 0.18, 0.18]} position={[0, -1.75, 0]}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_panel01.geometry}
        material={colorMaterials.back_panel01}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_panel_text01.geometry}
        material={nodes.back_panel_text01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_panel_logo01.geometry}
        material={materials.aluminium_purple_dark}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.front_glass01.geometry}
        material={nodes.front_glass01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      /> */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.screen_border01.geometry}
        material={nodes.screen_border01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.front_camera_border01.geometry}
        material={nodes.front_camera_border01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.front_camera_lens01.geometry}
        material={nodes.front_camera_lens01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.front_camera_border_part_01.geometry}
        material={nodes.front_camera_border_part_01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.front_panel01.geometry}
        material={nodes.front_panel01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_panel_part_01.geometry}
        material={nodes.back_panel_part_01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mini_usb_part_01.geometry}
        material={nodes.mini_usb_part_01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_panel_part_03.geometry}
        material={colorMaterials.mat_purple_dark}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_camera_glass01.geometry}
        material={nodes.back_camera_glass01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_camera_border01.geometry}
        material={nodes.back_camera_border01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_camera_border_part_01.geometry}
        material={nodes.back_camera_border_part_01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_camera_lens01.geometry}
        material={nodes.back_camera_lens01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_flash_glass01.geometry}
        material={nodes.back_flash_glass01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_flash_border_part_01.geometry}
        material={nodes.back_flash_border_part_01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_flash_border01.geometry}
        material={nodes.back_flash_border01.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_flash_lens01.geometry}
        material={materials.glossy_yellow}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_panel_top_button_part_01.geometry}
        material={colorMaterials.back_panel_top_button_part_01}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_panel_top_button01.geometry}
        material={colorMaterials.back_panel_top_button01}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_panel_part_04.geometry}
        material={nodes.back_panel_part_04.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mini_usb_part_03.geometry}
        material={colorMaterials.mini_usb_part_03}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mini_usb01.geometry}
        material={colorMaterials.mini_usb01}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_panel_part_02.geometry}
        material={nodes.back_panel_part_02.material}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_panel_dynamic01.geometry}
        material={materials.glossy_black}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.screen01.geometry}
        material={display}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
    </group>  
    </group>
  )
}

useGLTF.preload('/3DModels/Tablets/ipad_mini_6/scene.gltf')