import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { store } from '../../../../../../configureStore';
import { createAnimation, objectAnimation, setEnvironment } from '../Functions';

function getCoordinates(radian, width, height) {
    radian += Math.PI;
    let halfWidth = width * 0.5;
    let halfHeight = height * 0.5;
    let lineLength = Math.abs(width * Math.sin(radian)) + Math.abs(height * Math.cos(radian));
    let halfLineLength = lineLength / 2;

    let x0 = halfWidth + Math.sin(radian) * halfLineLength;
    let y0 = halfHeight - Math.cos(radian) * halfLineLength;
    let x1 = width - x0;
    let y1 = height - y0;

    return {x0, x1, y0, y1};
}

const Environment = () => {
    setEnvironment('/3DModels/EnvMaps/photo_studio_01_1k.hdr')
    return null
}

export default () => {
    const background = useSelector(state => state.ThreeSlice.present.background)
    const dimensions = {width: 100, height: 100}

    const { scene, gl } = useThree()

    useEffect(() => {
        function setBackground(background){
            var finalBackground
            if(background.priority === 'solid'){
                var rgx = /^rgba\(((,?\s*\d+){3}).+$/

                let alpha = parseFloat(background.solid.replace(/^.*,(.+)\)/,'$1'))
                let rgb = background.solid.replace(rgx, 'rgb($1)')
    
                // var finalBackground = new THREE.Color(background.solid);
                // gl.setClearColor(finalBackground, alpha > 1 ? alpha/100 : alpha)
                // scene.background = null
                finalBackground = [new THREE.Color(rgb), alpha > 1 ? alpha/100 : alpha] 
            }
            else if(background.priority === 'pattern'){
                finalBackground = new THREE.TextureLoader().load(background.image)
                finalBackground.encoding = THREE.sRGBEncoding
            }
            else {
                let canvas = document.getElementById('background-canvas')
                if(!canvas){
                    canvas = document.createElement('canvas')
                    canvas.id = 'background-canvas'
                }
                canvas.width = dimensions.width
                canvas.height = dimensions.height

                let ctx = canvas.getContext("2d")
                let cssAng = background.linear.deg * Math.PI / 180
                var dir = getCoordinates(cssAng, dimensions.width, dimensions.height);
                var gr = ctx.createLinearGradient(dir.x0,dir.y0,dir.x1,dir.y1);
                gr.addColorStop(background.linear.percent1/100, background.linear.color1);
                gr.addColorStop(background.linear.percent2/100, background.linear.color2);
                ctx.fillStyle = gr;
                ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                let url = canvas.toDataURL()
                finalBackground = new THREE.TextureLoader().load(url)
                finalBackground.encoding = THREE.sRGBEncoding
            }
            return finalBackground
        }
        // function clearState(state){
        //     for(let i=0; i < state.length; i++){
        //         if(state[i].isTexture){
        //             state[i].dispose()
        //         }
        //     }
        // }
        //     let list = []
        //     for(let i=0; i < background.animations.background.length; i++){
        //         let finalBackground = setBackground(background.animations.background[i].value)
        //         list.push(finalBackground)
        //     }
        //     setList(state => {
        //         clearState(state)
        //         return list
        //     })
            if(scene.background) scene.background.dispose()
            // If background doesn't change, set to current background
            let staticBackground = setBackground(background)
            if(Array.isArray(staticBackground)){
                scene.background = null
                gl.setClearColor(...staticBackground)
            }
            else {
                scene.background = staticBackground
            }

    }, [background])

    return  <Suspense fallback={null}>
                <Environment />
            </Suspense>
}