import { useEffect, useRef } from "react";
import { store } from "../../../../../../configureStore";
import { setClassParam } from "../../../../../slices/three-slice";
import { radians_to_degrees } from "../../../../Common/Functions";

const findValuebyPath = (obj, path) => path.split(".").reduce((o, key) => o && o[key] !== undefined && o[key] !== null ? o[key] : null, obj);

function almostEqual(v1, v2, epsilon) {
  if (epsilon == null) {
    epsilon = 0.00001;
  }
  return Math.abs(parseFloat(v1) - parseFloat(v2)) < epsilon;
};

export function connectSliders(data, ref){
    const timer = useRef()

    useEffect(() => {
        function updateValues(actions){
            timer.current = setTimeout(() => {
              for(let i=0; i < actions.length; i++){
                actions[i]()
              }
              updateValues(actions)
            }, 300)
          }

          clearTimeout(timer.current)
          if(ref){
            var event = new Event('changedValue');
            const actions = data.reduce((acc, el) => {
              let slider = document.getElementById(`${el.id}-slider`)
              let box = document.getElementById(`${el.id}-box`)
              acc.push(() => {
                let value = findValuebyPath(ref, el.path)
                if(el.path.includes('rotation')){
                  value = radians_to_degrees(value)
                }
                if(box !== document.activeElement && slider !== document.activeElement && (!almostEqual(slider.value, value) || !almostEqual(box.value, value))){
                  slider.value = value
                  box.value = value

                  slider.dispatchEvent(event);
                }
              })
              return acc
            }, [])
            updateValues(actions)
          }
    }, [ref])
}
