import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setClassParam, setSpecificClassParam } from '../../../../../../slices/three-slice';
import { createAnimation, objectAnimation, setDeviceColor, setDeviceScreenshot } from '../../Functions';
import * as THREE from 'three'
import { onDrag } from "../Controls";
import IPhone12 from "./Models/iphone-12";
import GooglePixel5 from "./Models/google-pixel-5";
import GooglePixel6 from "./Models/google-pixel-6";
import IMac24 from "./Models/imac";
import IPad from "./Models/ipad";
import IPadMini6 from "./Models/ipad-mini-6";
import MacBook from "./Models/macbook";
import MacBookProM114 from './Models/macbook-pro-m1-14'
import IPhone13 from "./Models/iphone-13";
import IPhone14 from "./Models/iphone-14";


const Device = ({ device }) => {
  const dispatch = useDispatch()
  const [group, setGroup] = useState({})

  // Create drag and drop
  const bindDrag = onDrag(group.ref, device, onDragEnd)

  function onDragEnd(positions){
    dispatch(setClassParam({item: device, param: 'position', value: positions}))
  }

  // Create position + rotation animations
  // createAnimation(device, group.ref)

  objectAnimation(device, group.ref)

  // Set device screenshot
  setDeviceScreenshot(device, group.display)

  // Set device color
  setDeviceColor(device.color, device.clay, group.colorMaterials)

  // set Materials after model loads
  function init(group) { 
    group.ref.traverse((item) => item.castShadow = true)
    setGroup(group)
  }

  const deviceProps = {
    init,
    name: `devices-${device.id}`,
    ...bindDrag(),
    castShadow: true,
    userData: {ready: group.ref ? true : false},
    position: [device.position.x,device.position.y,device.position.z],
    rotation: [device.rotation.x,device.rotation.y,device.rotation.z],
    scale: [device.scale, device.scale, device.scale]
  }

  const frames = {
    'iPhone 14': IPhone14,
    'iPhone 13': IPhone13,
    'iPhone 12': IPhone12,
    'Google Pixel 5': GooglePixel5,
    'Google Pixel 6': GooglePixel6,
    'iPad': IPad,
    'iPad Mini 6': IPadMini6,
    'MacBook': MacBook,
    'MacBook Pro M1 14': MacBookProM114,
    'iMac 24': IMac24,
  }

  const DeviceComponent = frames[device.frame]

  return <DeviceComponent {...deviceProps} /> 
};

const Devices = () => {
  let devices = useSelector((state) => state.ThreeSlice.present.devices);

  return devices.map((device, index) => {
    if(Object.keys(device).length){
      return (
        <Suspense fallback={null} key={index}>
            <Device device={device} />
        </Suspense>
      );
    }
  });
};

export default Devices;
