import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setBackground } from '../../../../../slices/three-slice'
import BackgroundPicker from '../../../../Common/BackgroundPicker/BackgroundPicker'
import DraggableSetting from '../../../../Common/DraggableSetting/DraggableSetting'
import * as styles from './background.module.scss'

    
const BackgroundModal = () => {
    const dispatch = useDispatch()
    const background = useSelector(state => state.ThreeSlice.present.background)
    const dimensions = useSelector(state => state.ThreeSlice.present.dimensions)

    return(
        <DraggableSetting handle={'Background'} position={{x: 10, y: 10}} show={background.modal} onClose={() => dispatch(setBackground({modal: false}))}>
            <div style={{width: 280}}>
                <BackgroundPicker background={background} dimensions={dimensions} setBackground={(value) => dispatch(setBackground(value))}/>
            </div>
        </DraggableSetting>
    )
}

export default BackgroundModal;