import { Html } from "@react-three/drei";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { useFrame, useThree, extend } from "@react-three/fiber";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { container, parentContainer } from "./transformer.module.scss";
import * as THREE from 'three';
import { setClassParam } from "../../../../../../slices/three-slice";
import { degrees_to_radians } from "../../../../../Common/Functions";

extend({ TransformControls });
const Transformer = () => {
  const dispatch = useDispatch();
  const currentItem = useSelector((state) => state.ThreeSlice.present.currentItem);

  const [frame, mode] = useSelector((state) => {
    if (currentItem.class === "devices") {
      return [state.ThreeSlice.present.devices[currentItem.id]?.frame, state.ThreeSlice.present.devices[currentItem.id]?.mode]
    }
    return [null, null];
  });

  const playing = useSelector((state) => state.ThreeSlice.present.playing);
  const format = useSelector((state) => state.ThreeSlice.present.recording.format);

  const { camera, gl, scene, size } = useThree();
  var object = scene.getObjectByName(`${currentItem.class}-${currentItem.id}`);

  let box = new THREE.Box3()
  let vector = new THREE.Vector3()

  const ref = useRef();
  const transformRef = useRef();

  let cameraScale = Math.tan(degrees_to_radians(camera.fov / 2)) * 2;



  useEffect(() => {
    if(ref.current){
    ref.current.parentNode.parentNode.className = parentContainer
    }
    if(transformRef.current){
        transformRef.current.setSpace("local");
        transformRef.current.setMode("rotate");
        // transformRef.current.attach(object);

        function onMouseUp(e){
            let {x, y, z} = e.target.object.rotation
            dispatch(setClassParam({param: 'rotation', value: {x, y, z} }))
        }
        transformRef.current.addEventListener("mouseUp", onMouseUp, true);
        return () => {
            transformRef.current?.removeEventListener("mouseUp", onMouseUp, true);
        }
    }
  }, [mode])

  useEffect(() => {
    object = scene.getObjectByName(`${currentItem.class}-${currentItem.id}`);
    transformRef.current?.detach();
    transformRef.current?.attach(object);
  }, [frame, currentItem]);

  useEffect(() => {
    if(transformRef.current){
      if(playing || format){
        transformRef.current.detach();
      }
      else{
        transformRef.current.attach(object);
      }
    }
  }, [playing, format, mode])

  function getCorners(min, max){
      let lowX, highX, lowY, highY
      let points =
      [
          [min.x, min.y, min.z], // 000
          [min.x, min.y, max.z], // 001
          [min.x, max.y, min.z], // 010
          [min.x, max.y, max.z], // 011
          [max.x, min.y, min.z], // 100
          [max.x, min.y, max.z], // 101
          [max.x, max.y, min.z], // 110
          [max.x, max.y, max.z]  // 111
      ];
      for(let i=0; i < points.length; i++){

          let projectedPoint = vector.set(...points[i]).project(camera)
          if(!lowX || projectedPoint.x < lowX){
              lowX = projectedPoint.x
          }
          if(!highX || projectedPoint.x > highX){
              highX = projectedPoint.x
          }
          if(!lowY || projectedPoint.y < lowY){
              lowY = projectedPoint.y
          }
          if(!highY || projectedPoint.y > highY){
              highY = projectedPoint.y
          }
      }

      let left = (lowX + 1) * gl.domElement.clientWidth/2;
      let top = (1 - highY) * gl.domElement.clientHeight/2;
      let width = ((highX + 1) * gl.domElement.clientWidth/2) - left
      let height = ((1 - lowY) * gl.domElement.clientHeight/2)  - top
      return {left, top, width, height}
  }

  useFrame(({camera}) => {
      if(ref.current && (!currentItem.class !== 'device' || mode === 'rotate')){
          if(object){
              if(object.visible && !playing && !format){
                  if(object.type === "Sprite"){
                      const objectPos = vector.setFromMatrixPosition(object.matrixWorld)
                      objectPos.project(camera)

                      let height = size.height * camera.zoom * object.scale.y / cameraScale
                      let width = height * object.userData.ratio

                      const widthHalf = size.width / 2
                      const heightHalf = size.height / 2
                      let left = objectPos.x * widthHalf + widthHalf - width/2
                      let top = -(objectPos.y * heightHalf) + heightHalf - height/2

                      ref.current.style.transform = `translate(${left}px, ${top}px) rotate(${object.material.rotation < 0 ? Math.abs(object.material.rotation) : `-${object.material.rotation}`}rad)`
                      ref.current.style.width = width + 'px'
                      ref.current.style.height = height + 'px'
                  }
                  else if(object.type === "Group") {
                      const {min, max} = box.setFromObject(object)
                      const {left, top, width, height} = getCorners(min, max)

                      ref.current.style.transform = `translate(${left}px, ${top}px)`
                      ref.current.style.width = width + 'px'
                      ref.current.style.height = height + 'px'
                  }

                  ref.current.style.display = 'block'
              }
              else {
                  ref.current.style.display = 'none'
              }
          }
          else{
              ref.current.style.display = 'none'
          }
      }

  })

  function onPointerDown(e) {
      let boxDimensions = ref.current.getBoundingClientRect()
      let x = boxDimensions.left + boxDimensions.width/2
      let y = boxDimensions.top + boxDimensions.height/2

      let center = {x, y}

      let initialDifference = Math.abs(center.x - e.clientX) + Math.abs(center.y - e.clientY)
      let initScale = object.scale.y
      let ratio = object.userData.ratio || 1
      let newScale
      function scaleObject(e){
          let newDifference = Math.abs(center.x - e.clientX) + Math.abs(center.y - e.clientY)
          newScale = initScale + initScale*(newDifference - initialDifference) /initialDifference

          object.scale.x = newScale * ratio
          object.scale.y = newScale
          object.scale.z = newScale
      }
      function onMouseUp(){
          if(Number.isFinite(newScale)){
              dispatch(setClassParam({param: 'scale', value: newScale}))
          }
          else{
              object.scale.x = initScale * ratio
              object.scale.y = initScale
              object.scale.z = initScale
          }

          document.removeEventListener('mousemove', scaleObject)
          document.removeEventListener('mouseup', onMouseUp)
      }
      document.addEventListener('mousemove', scaleObject)
      document.addEventListener('mouseup', onMouseUp)
  }

  return currentItem.class === 'devices' && mode === 'rotate' ? (
    <transformControls ref={transformRef} args={[camera, gl.domElement]} />
  ) : (
    <Html
      fullscreen
      prepend
      zIndexRange={[0, 0]}
      style={{ pointerEvents: "none", top: 0, left: 0 }}
    >
      <div style={{ position: "absolute" }} className={container} ref={ref}>
        <div>
          {[...Array(4)].map((el, i) => (
            <div key={i} onMouseDown={onPointerDown} />
          ))}
        </div>
      </div>
    </Html>
  )
};

export default Transformer;
