import TWEEN from '@tweenjs/tween.js';
import { useCallback, useEffect, useRef, useState } from "react"
import { useFrame, useThree, useLoader, Canvas } from '@react-three/fiber';
import CanvasKitInit from 'canvaskit-wasm/bin/full/canvaskit.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { store } from '../../../../../configureStore';
import * as THREE from 'three'
import { useDispatch, useSelector } from 'react-redux';
import { setLottieAnimation, setLottieFont, setSpecificClassParam } from '../../../../slices/three-slice';
import { stat } from 'fs';
import { fetchFontFamily, getImageDimensions, usePrevious } from '../../../Common/Functions';
import DefaultTextAnimation from '../../../../assets/animations/texts/text-default.json'
import TextAnimators from '../../../../assets/animations/texts/animators.json'
import isEmpty from 'lodash/isEmpty';
import Firebase from '../../../../firebase/index'

export function objectAnimation(item, ref){
    let duration = useSelector(state => state.ThreeSlice.present.duration)
    const start = item.class !== 'camera' ? item.time.start : 0
    const end = item.class !== 'camera' ? item.time.end : duration

    const keys = Object.keys(item.animations)
    let groups = useRef(keys.reduce((acc, key) => {
            acc[key] = new TWEEN.Group()
            return acc
    }, {}))
    let startTimes = useRef(keys.reduce((acc, key) => {
        acc[key] = 0
        return acc
    }, {}))


    onTimeUpdate(() => {
        if(ref){
            let currentTime = store.getState().ThreeSlice.present.currentTime
            if(currentTime >= start && currentTime <= end){
                if(item.class !== 'camera') ref.visible = true
                keys.forEach(key => {
                    if(item.animations[key].length){
                        let time
                        if(currentTime < item.animations[key][0].time + start){
                            time = startTimes.current[key] + start + item.animations[key][0].time
                        }
                        else if(currentTime > item.animations[key][item.animations[key].length - 1].time + start){
                            time = startTimes.current[key] + start + item.animations[key][item.animations[key].length - 1].time
                        }
                        else {
                            time = startTimes.current[key] + currentTime
                        }
                        groups.current[key].update(time, true)
                    }
                }); 
              }
              else{
                ref.visible = false
              }
            }
    })

    useEffect(() => {
        // TODO - Check what changed
        if(ref){
            for(let i=0; i < keys.length; i++){
                let key = keys[i]
                let tweens = []
                //remove previous animation
                groups.current[key].removeAll()
                
                //create animations if it exists
                let data = item.animations[key]
                if(data.length > 1){
                    for(let j=0; j < data.length - 1; j++){
                        let start, finish, onUpdateCallback, onCompleteCallback
                        let t1 = data[j]
                        let t2 = data[j + 1]
        

                        let duration = t2.time - t1.time
                        let easing = t2.easing.split('.')

                        if(item.class === 'devices'){
                            start = typeof t1.value === 'object' ? Object.values(t1.value) : Array(3).fill(t1.value)
                            finish = typeof t2.value === 'object' ? Object.values(t2.value) : Array(3).fill(t2.value)
                            onUpdateCallback = (start) => {
                                ref[key].set(...start)
                            }
                        }
                        if(item.class === 'images' || item.class === 'texts'){
                            if(key === 'position' || key === 'scale'){
                                start = typeof t1.value === 'object' ? Object.values(t1.value) : Array(3).fill(t1.value)
                                finish = typeof t2.value === 'object' ? Object.values(t2.value) : Array(3).fill(t2.value)
                                onUpdateCallback = (start) => {
                                    ref[key].set(...start)
                                }
                            }
                            else {
                                start = {
                                    value: t1.value
                                }
                                finish = {
                                    value: t2.value
                                }
                                onUpdateCallback = (start) => {
                                   ref.material[key] = start.value
                                }
                            }
                        }
                        else if (item.class === 'camera'){
                            if(key === 'position'){
                                let startSphereVector = new THREE.Vector3(
                                    t1.value.x,
                                    t1.value.y,
                                    t1.value.z
                                )
                        
                                let endSphereVector = new THREE.Vector3(
                                    t2.value.x,
                                    t2.value.y,
                                    t2.value.z
                                )
                        
                                let axis = startSphereVector.clone().cross(endSphereVector).normalize()
                                
            
                                start = {
                                    angle: 0,
                                  }
                                finish = {
                                    angle: startSphereVector.angleTo(endSphereVector),
                                }
                                onUpdateCallback = (start) => {
                                    ref.position.copy(startSphereVector).applyAxisAngle(axis, start.angle)
                                    ref.lookAt(0,0,0)
                                    ref.updateProjectionMatrix()
                                }
                            }
                            else if(key === 'zoom'){
                                start = {
                                    zoom: t1.value
                                }
                                finish = {
                                    zoom: t2.value
                                }
                                onUpdateCallback = (start) => {
                                    ref.zoom = start.zoom
                                    ref.updateProjectionMatrix()
                                }
                            }

                        }
    
                        let tween = new TWEEN.Tween(start, groups.current[key]).to(finish, duration).onUpdate(() => {
                            onUpdateCallback(start)
                        })
                        // Set easing
                        tween.easing(TWEEN.Easing[easing[0]][easing[1]])

                        if(tweens.length){
                            tweens[j - 1].chain(tween)
                        }
                        tweens.push(tween)
                    }
                    // Start animation and save start time after all tweens have been chained
                    let delayTime = start + data[0].time
                    tweens[0].delay(delayTime).start()
                    startTimes.current[key] = tweens[0]._startTime - delayTime

                    // Set animation to current time
                    let currentTime = store.getState().ThreeSlice.present.currentTime
                    groups.current[key].update(startTimes.current[key] + currentTime, true)
                }
            }  
        }
      }, [start, end, item.animations, ref])

      useEffect(() => {
          if(ref){
            let currentTime = store.getState().ThreeSlice.present.currentTime
            if(currentTime >= start && currentTime <= end){
                if(item.class !== 'camera') ref.visible = true
            }
            else{
                if(item.class !== 'camera') ref.visible = false
            }
        }
      }, [start, end, ref])
}

export function createAnimation(item, ref){
    let animation = useRef(new TWEEN.Group())
    let startTime = useRef(0)
    // let ready = false

    const {scene, gl} = useThree()

    let onNewFrame = useCallback(() => {
        let currentTime = store.getState().ThreeSlice.present.currentTime
        if(item.class === 'camera' && !store.getState().ThreeSlice.present.orbiting){
            animation.current.update(startTime.current + currentTime, true)
        }
        else if(item.class === 'background'){
            animation.current.update(startTime.current + currentTime, true)
        }
        else if(item.class === 'devices'){
            if(ref && !store.getState().ThreeSlice.present.dragging){
            if(currentTime >= item.time.start && currentTime <= item.time.end){
                ref.visible = true
                animation.current.update(startTime.current + currentTime, true)
              }
              else{
                ref.visible = false
              }
            }
        }
    }, [item, ref])

    onTimeUpdate((state, delta) => {
        onNewFrame()
      })

    useEffect(() => {
        if(ref && item.timestamps){
            animation.current.removeAll() // remove all previous animations
            let tweens = []

            for(let i=0; i < item.timestamps.length - 1; i++){
                let start, finish, onUpdateCallback
                let t1 = item.timestamps[i]
                let t2 = item.timestamps[i + 1]
                let duration = t2.time - t1.time
                let easing = item.timestamps[i + 1].easing?.split('.')
  
                if(item.class === 'devices'){
                  start = {
                      position: {...t1.position},
                      rotation: {...t1.rotation}
                    }
                  finish = {
                      position: {...t2.position},
                      rotation: {...t2.rotation}
                  }
                  onUpdateCallback = (start) => {
                      ref.position.set(start.position.x, start.position.y, start.position.z)
                      ref.rotation.set(start.rotation.x, start.rotation.y, start.rotation.z)
                  }
                }
                else if (item.class === 'camera'){
                    let startSphereVector = new THREE.Vector3(
                        t1.position.x,
                        t1.position.y,
                        t1.position.z
                    )
            
                    let endSphereVector = new THREE.Vector3(
                        t2.position.x,
                        t2.position.y,
                        t2.position.z
                    )
            
                    let axis = startSphereVector.clone().cross(endSphereVector).normalize()
                    
                    start = {
                        angle: 0,
                        zoom: t1.zoom
                      }
                    finish = {
                        angle: startSphereVector.angleTo(endSphereVector),
                        zoom: t2.zoom
                    }
                    onUpdateCallback = (start) => {
                        ref.position.copy(startSphereVector).applyAxisAngle(axis, start.angle)
                        ref.zoom = start.zoom
                        ref.lookAt(0,0,0)
                        ref.updateProjectionMatrix()
                    }
                }
                else if (item.class === 'background'){
                    start = {
                        value: null,
                      }
                    finish = {
                        value: null,
                    }
                    onUpdateCallback = () => {
                        if(item.list[i]){
                            if(Array.isArray(item.list[i])){
                                let currentColor = gl.getClearColor()
                                if(scene.background !== null ||
                                    currentColor.r !== item.list[i][0].r ||
                                    currentColor.g !== item.list[i][0].g ||
                                    currentColor.b !== item.list[i][0].b ||
                                    gl.getClearAlpha() !== item.list[i][1]){
                                        scene.background = null
                                        gl.setClearColor(...item.list[i])
                                    }
                            }
                            else if(scene.background !== item.list[i]){
                                scene.background = item.list[i]
                            }
                        }
                    }
                }

  
                let tween = new TWEEN.Tween(start, animation.current).to(finish, duration).onUpdate(() => {
                    onUpdateCallback(start)
                })
                if(easing){
                    tween.easing(TWEEN.Easing[easing[0]][easing[1]])
                }

                if(tweens.length){
                  tweens[i - 1].chain(tween)
                }
                tweens.push(tween)
            }
            // Start animation and save start time after all tweens have been chained
            let delayTime = item.time.start
            tweens[0].delay(delayTime).start()
            startTime.current = tweens[0]._startTime - delayTime

            // Set animation to current time
            let currentTime = store.getState().ThreeSlice.present.currentTime
            animation.current.update(startTime.current + currentTime, true)

        }
      }, [item.timestamps, item.list, ref])
}

export function createLottieAnimationsCanvas(){
    const [SK, setSK] = useState(null) 
    const [ready, setReady] = useState(false)
    const dimensions = useSelector(state => state.ThreeSlice.present.lottieCanvas.dimensions)
    const ref = useRef()
    const canvasKit = useRef()

        // function create Canvas
        useEffect(() => {
            CanvasKitInit().then((CanvasKit) => {
              var canvas = document.createElement('canvas')
              canvasKit.current = CanvasKit
              ref.current = canvas
              setReady(true)
      
            })
          }, [])

    useEffect(() => {
        if(ready && ref.current && (dimensions.width !== ref.current.width || dimensions.height !== ref.current.height)){
            ref.current.width = dimensions.width
            ref.current.height = dimensions.height


            let surface = canvasKit.current.MakeCanvasSurface(ref.current);
            let skcanvas = surface.getCanvas();
            setSK({canvas: ref.current, CanvasKit: canvasKit.current, surface, skcanvas})
        }
    }, [ready, dimensions, ref.current, canvasKit.current])

    return SK
}

export function createLottieAnimation(ref, item, sk){
    const dispatch = useDispatch()
    const bounds = useSelector(state => state.ThreeSlice.present.lottieCanvas.animations[`${item.class}-${item.id}`]?.bounds)

    const [data, setData] = useState()

    if(data && bounds){
        var {animation, texture, tempCanvasContext, animationDuration, introDuration, outroDuration, totalDuration, animationHeight, animationWidth} = data
        var {skcanvas, canvas, CanvasKit} = sk
        var boundsRect =  CanvasKit.LTRBRect(...bounds);
    }

    function drawTexture(time){
        // seek time must be between 1 and 0
        if(time < introDuration){
            if(item.textAnimation.intro){
                time /= animationDuration
            }
            else {
                time = introDuration / animationDuration
            }
        }
        else if(time >= introDuration && time <= totalDuration - outroDuration){
            time = introDuration / animationDuration
        }
        else if(time > totalDuration - outroDuration){
            if(item.textAnimation.outro){
                time = (animationDuration + time - totalDuration) / animationDuration
            }
            else {
                time = introDuration / animationDuration
            }
        }
        // Clear webgl canvas
        skcanvas.clear()

        // Seek animation and render
        animation.seek(time);
        animation.render(skcanvas, boundsRect);
        skcanvas.flush();

        // Clear canvas and draw 
        tempCanvasContext.clearRect(0, 0, animationWidth, animationHeight);
        tempCanvasContext.fillRect(0, 0, animationWidth, animationHeight);
        tempCanvasContext.drawImage(canvas,boundsRect[0],boundsRect[1],animationWidth,animationHeight,0,0,animationWidth, animationHeight);

        // Update texture
        texture.needsUpdate = true
    }

    onTimeUpdate(() => {
        if(data){
        let currentTime = store.getState().ThreeSlice.present.currentTime
        if(currentTime >= item.time.start && currentTime <= item.time.end){
            ref.visible = true
            // Draw animation at current time and set texture
            drawTexture(currentTime - item.time.start)
          }
          else{
            ref.visible = false
          }
        }
    })

    useEffect(() => {
        if(data && bounds){
            let currentTime = store.getState().ThreeSlice.present.currentTime
            drawTexture(currentTime - item.time.start)
        }
    }, [data, bounds, item.time])

    useEffect(() => {
        async function initData(){
            let {CanvasKit, canvas} = sk
            let anim, assets

            if(item.class === 'texts'){
                let family = item.font?.family || item.fontFamily;
                let style = item.font?.style || item.fontWeight;
                // Change texts/colors in animation
                anim  = await changeAnimationTexts(item.textAnimation, item.text, item.color, family, style, item.font?.custom, item.alignment)
                // If the animation is text --> load the font first
                assets = await loadAnimationFont(family, style, item.font?.custom)
            }
            else if(item.class === 'images'){
                // Change image
                anim = await changeAnimationImage(item.animation, item.image)

            }
            let json = JSON.stringify(anim)

            // Create animation and set bounds
            const animation = CanvasKit.MakeManagedAnimation(json, assets);

            // Get animation dimensions
            let animationWidth = anim.w * Math.sqrt(item.scale * 30)
            let animationHeight =  anim.h * Math.sqrt(item.scale * 30)

            dispatch(setLottieAnimation({item, width: animationWidth, height: animationHeight}))

            // Create 2D canvas for the animation
            let tempCanvas = document.getElementById(`temporary-canvas-${item.class}-${item.id}`)
            if(!tempCanvas){
                tempCanvas = document.createElement('canvas');
                tempCanvas.id = `temporary-canvas-${item.class}-${item.id}`
            }

            tempCanvas.width = animationWidth;
            tempCanvas.height = animationHeight;
            let tempCanvasContext = tempCanvas.getContext('2d')
            tempCanvasContext.fillStyle = `rgba(${item.color[0] * 255},${item.color[1] * 255},${item.color[2] * 255},0.01)`;


            var texture = new THREE.CanvasTexture(tempCanvas)
            // texture.magFilter = THREE.LinearFilter
            texture.minFilter = THREE.LinearMipmapLinearFilter
            texture.premultiplyAlpha = false

            // Create constants
            let animationDuration = animation.duration() * 1000
            let introDuration = item.textAnimation.intro.type !== 'None' ? item.textAnimation.intro.duration : 0
            let outroDuration = item.textAnimation.outro.type !== 'None' ? item.textAnimation.outro.duration : 0
            let totalDuration = item.time.end - item.time.start

            // Set all required variables
            setData(state => {
                if(state && state.texture){
                    state.texture.dispose()
                }
                return {animation, texture, tempCanvasContext, animationDuration, introDuration, outroDuration, totalDuration, animationHeight, animationWidth}
            })
        }
        if(sk){
            initData()
        }
    }, [sk, item.textAnimation, item.time, item.image, item.text, item.color, item.alignment,item.scale, item.fontWeight, item.fontFamily, item.font])

    return data || {}
}

export function onTimeUpdate(action){
    const prevTime = useRef(null)

    return useFrame((state, delta) => {
        // Get current time
        let currentTime = store.getState().ThreeSlice.present.currentTime

        // If time has changed --> do updates and sync times
        if(prevTime.current !== currentTime){
            prevTime.current = currentTime
            action(currentTime)
        }
    })
}


async function loadAnimationFont(family, weight, custom){
    let {ttf} = await fetchFont(family, weight, custom)
    return {'current-font': ttf }
}

async function fetchFont(family, weight, custom){
    let url, name = `${custom ? 'custom-' : ''}${family}-${weight}` 
    let savedFont = store.getState().ThreeSlice.present.lottieCanvas.fonts[name]

    if(savedFont){
        return {name, ttf: savedFont}
    }
    else{
        if(custom){
            url = await Firebase.getFileFromStorage('fonts', custom)
        }
        else{
            let fetchedFamily = await fetchFontFamily(family)
            url = fetchedFamily.files[weight].replace('http://', 'https://')
        }
        let response = await fetch(url)
        if(response.ok){
            let ttf = await response.arrayBuffer()
            store.dispatch(setLottieFont({[name]: ttf}))
            return {name, ttf}
        }
    }
}

function cloneJson(json){
    return JSON.parse(JSON.stringify(json));
}

function isAnimationArray(anim){
    return Array.isArray(anim?.k)
}

async function changeAnimationTexts(textAnimations, text, color, family, weight, custom, alignment){
    let animation = cloneJson(DefaultTextAnimation);
    let layers = animation.layers

    // Change layer text + color
    layers[0].t.d.k[0].s.t = text.replace(/\n/g,'\r')
    layers[0].t.d.k[0].s.fc = color

    let {width, height, singleLineHeight, padding} = await getTextDimensions(text, layers[0].t.d.k[0].s.s, layers[0].t.d.k[0].s.lh, family, weight, custom)

    // Get relative position of layer to animation
    // let widthRatio = layers[0].ks.p.k[0] / newAnimation.w
    // let heightRatio = layers[0].ks.p.k[1] / newAnimation.h

    //  Align text
    let j = alignment === 'left' ? 3 : alignment === 'right' ? 1 : 2
    layers[0].t.d.k[0].s.j = j

    // Set anchor point to middle of text layer
    let anchorX
    let anchorY = ((height-padding)/2) - singleLineHeight
    if(j === 3){
        anchorX = width/2
    }
    else if(j === 2){
        anchorX = 0
    }
    else if(j === 1){
        anchorX = -width/2
    }
    layers[0].ks.a.k[0] = anchorX
    layers[0].ks.a.k[1] = anchorY

    //Set animation width + height
    animation.w = width * 1.5
    animation.h = height * 1.5

    // Set layer position inside of animation
    layers[0].ks.p.k[0] = animation.w / 2
    layers[0].ks.p.k[1] = animation.h / 2

    // Add intro + outro animations if any
    let introTime = 0
    let outroTime = 0
    if(textAnimations.intro.type !== 'None'){
        let intro = cloneJson(TextAnimators[textAnimations.intro.type])
        introTime = textAnimations.intro.duration * 24 / 1000

        // Edit duration, and letter/word/line type
        if(isAnimationArray(intro.s.s)){
            intro.s.s.k[0].t = 0
            intro.s.s.k[1].t = introTime
        }
        if(isAnimationArray(intro.s.e)){
            intro.s.e.k[0].t = 0
            intro.s.e.k[1].t = introTime
        }
        if(isAnimationArray(intro.s.o)){
            intro.s.o.k[0].t = 0
            intro.s.o.k[1].t = introTime
        }
        intro.s.b = textAnimations.intro.by === 'letters' ? 1 : textAnimations.intro.by === 'words' ? 3 : 4
        layers[0].t.a[0] = intro
    }
    if(textAnimations.outro.type !== 'None'){
        let outro = cloneJson(TextAnimators[textAnimations.outro.type])
        outroTime = textAnimations.outro.duration * 24 / 1000
        // Edit duration, and letter/word/line type
        if(isAnimationArray(outro.s.s)){
            let s1 = [...outro.s.s.k[0].s]
            let s2 = [...outro.s.s.k[1].s]
    
            outro.s.s.k[0].t = introTime
            outro.s.s.k[1].t = introTime + outroTime
            outro.s.s.k[0].s = s2
            outro.s.s.k[1].s = s1
        }
        if(isAnimationArray(outro.s.e)){
            let e1 = [...outro.s.e.k[0].s]
            let e2 = [...outro.s.e.k[1].s]
                    
            outro.s.e.k[0].t = introTime
            outro.s.e.k[1].t = introTime + outroTime
            outro.s.e.k[0].s = e2
            outro.s.e.k[1].s = e1
        }
        if(isAnimationArray(outro.s.o)){
            let e1 = [...outro.s.o.k[0].s]
            let e2 = [...outro.s.o.k[1].s]
                    
            outro.s.o.k[0].t = introTime
            outro.s.o.k[1].t = introTime + outroTime
            outro.s.o.k[0].s = e2
            outro.s.o.k[1].s = e1
        }
        layers[0].t.a[1] = outro
        outro.s.b = textAnimations.outro.by === 'letters' ? 1 : textAnimations.outro.by === 'words' ? 3 : 4
    }

    animation.op = introTime + outroTime + 1
    layers[0].op = introTime + outroTime + 1

    return animation
}

async function getTextDimensions(text, size, lineHeight, family, weight, custom) {
    // Get style and weight
    let s = weight.split(/(\d+)/).filter(Boolean)
    let style = s.join(' ').replace('regular', 'normal').replace('Custom', 'normal')

    // Check if font already exists
    let exists = store.getState().ThreeSlice.present.lottieCanvas.fonts[`${custom ? 'custom' : ''}${family}-${weight}`]
    //If not, add it
    if(!exists && family !== 'Poppins'){
        let {name, ttf} = await fetchFont(family, weight, custom)
        let font = new FontFace(name, ttf);
        await font.load();
        document.fonts.add(font);
    }
    // // Get or create p element
    var p = document.getElementById("measure-text-p")
    if(!p){
        p = document.createElement('p')
        p.id = 'measure-text-p'
        p.style.width = 'fit-content'
        p.style.position = 'absolute'
        p.style.whiteSpace = 'pre'
        p.style.lineHeight = `${lineHeight}px`
        p.style.top = '-9999px'
        p.style.left = '-9999px'
        document.body.appendChild(p)
    }
    let singleLineHeight = getTextHeight(text.split('\n', 1)[0], `${style} ${size}px ${family}`)
    let padding = lineHeight - singleLineHeight

    p.style.font = `${style} ${size}px ${family}`;

    p.textContent = text
    let dimensions = p.getBoundingClientRect();
    
    let {width, height} = dimensions

    height += 1 
    width += 1

    return {width, height, singleLineHeight, padding}
}

export const setEnvironment = (url) => {
    const { gl, scene } = useThree();
    const pmremGenerator = new THREE.PMREMGenerator(gl);
    pmremGenerator.compileEquirectangularShader();
  
    const hdrEquirect = useLoader(RGBELoader, url);
  
    const hdrCubeRenderTarget = pmremGenerator.fromEquirectangular(hdrEquirect);
    hdrEquirect.dispose();
    pmremGenerator.dispose();

    scene.environment = hdrCubeRenderTarget.texture;
};


export function setDeviceColor(color, clay, materials){

    useEffect(() => {
        if(materials){
            // Change Surface
            materials.forEach((item) => {
                item.roughness =  clay ? 0.5 : 0
            } )
        }
    }, [clay, materials])

    useEffect(() => {
        if(materials){
            // Change Color
            let materialColor = new THREE.Color().setStyle( color ).convertSRGBToLinear()
            materials.forEach((item) => {
                item.color = materialColor
                item.needsUpdate = true
            } )
        }
    }, [color, materials])

    useEffect(() => {
        if(materials){
            // Initialise 
            materials.forEach((item) => {
                if(item.map && item.map.isTexture){
                    item.map.dispose()
                }
                if(item.emissive && item.emissive.isTexture){
                    item.emissive.dispose()
                }
                item.emissive = null
                item.map = null
                item.envMapIntensity = 1
                item.metalness = 1
            } )
        }
    }, [materials])
}

export function setDeviceScreenshot(device, display){
    const dispatch = useDispatch()
    const playing = useSelector(state => state.ThreeSlice.present.playing)
    const {screenshot, frame, id, videoRef, time} = device
    const flipY = ['iPhone 12', 'iPad'].includes(frame)
    const isVideo = videoRef?.nodeName?.toLowerCase() === "video"

    function setVideoRef(video){
      dispatch(setSpecificClassParam({id, class: 'devices', value: {videoRef: video}}))
    }

    onTimeUpdate(() => {
        if(!playing && isVideo){
            let currentVideoTime = (store.getState().ThreeSlice.present.currentTime - time.start) / 1000
            videoRef.currentTime = currentVideoTime
        }
    })

    useEffect(() => {
        if(isVideo){
            if(playing && !videoRef.playing) videoRef.play()
            else videoRef.pause()
        }
    }, [playing, videoRef, isVideo])

    useEffect(() => {
        if(display){
            display.color.setRGB(1,1,1)
            display.emissive.setRGB(0,0,0)
            display.envMapIntensity = 0
            display.metalness = 0
        }
    }, [display])

    useEffect(() => {
        if(screenshot && display){
            if(display.emissiveMap){
                display.emissiveMap.dispose()
                display.emissive = new THREE.Color(0, 0, 0)
                display.emissiveMap = null
            }
            // If the screenshot is an image
            if(screenshot.includes('data:image')){
                setVideoRef(null)


                if(display.map.constructor.name !== 'Texture'){
                    const texture = new THREE.TextureLoader().load( screenshot );

        
                    display.map.dispose()
                    display.map = texture
                }
                else if(display.map.image){
                    display.map.image.src = screenshot
                    display.map.needsUpdate = true
                }
            }
            // Or if a video is uploaded
            else if(screenshot.includes('data:video')){
                // Create video element
                let video = isVideo ? videoRef : document.createElement('video')
                video.muted = true
                if(!isVideo){
                    setVideoRef(video)
                }
                video.addEventListener('loadeddata', () => {
                    let currentVideoTime = (store.getState().ThreeSlice.present.currentTime - time.start) / 1000
                    video.currentTime = currentVideoTime
                    display.map.update()
                }, {once: true});

                video.src = screenshot
    
                // If video doesn't exist dispose of old texture and load video texture
                if(!display.map.isVideoTexture){
                    display.map?.dispose()
                    display.emissiveMap?.dispose()
                    const texture = new THREE.VideoTexture( video );
                    display.map = texture
                }

            }
            display.map.encoding = THREE.sRGBEncoding
            display.map.generateMipmaps = false;
            display.map.minFilter = THREE.LinearFilter
            display.map.flipY = flipY

        }
    }, [screenshot, display, time, flipY, videoRef, isVideo])
}

function getTextHeight(text, font) {
    var canvas = document.getElementById('text-measure-canvas')
    if(!canvas){
        canvas = document.createElement('canvas')
        canvas.id = 'text-measure-canvas'
    }
    var ctx = canvas.getContext('2d')
    ctx.font = font
    let measuredText = ctx.measureText(text)
    let height = measuredText.actualBoundingBoxAscent
    return height
  }


async function changeAnimationImage(animation, image){
    let newAnimation = JSON.parse(JSON.stringify(animation.json));

    let {width, height} = await getImageDimensions(image)

    // Change image layer
    let asset = newAnimation.assets[0]
    asset.p = image
    asset.w = width
    asset.h = height

    newAnimation.h = height
    newAnimation.w = width

    if(animation.from){
        let keyframes = newAnimation.layers[0].ks.p.k
        let start, end

        // If top or bottom --> increase height
        if(animation.from === 'Bottom' || animation.from === 'Top'){
            let padding = height * 0.1
            newAnimation.h = height + padding
            
            if(animation.from  === 'Bottom'){
                start = [0, padding, 0]
                end = [0, 0, 0]
            }
            // Has to be top otherwise
            else{
                start = [0, 0, 0]
                end = [0, padding, 0]
            }
        }
        // Else if Right or left --> increase width
        else if(animation.from === 'Right' || animation.from === 'Left'){
            let padding = width * 0.1
            newAnimation.w = width + padding
            
            if(animation.from  === 'Right'){
                start = [padding, 0, 0]
                end = [0, 0, 0]
            }
            // Has to be left otherwise
            else{
                start = [0, 0, 0]
                end = [padding, 0, 0]
            }
        }
        // Set keyframe positions
        for(let i=0; i < keyframes.length; i++){
            if(i === 0 || i === 3){
                keyframes[i].s = start
            }
            else if(i === 1 || i === 2){
                keyframes[i].s = end
            }
        }
    }

    return newAnimation
}