import React from "react";
import { useSelector } from "react-redux";
import AttributionModal from "../../../Modals/AttributionModal/AttributionModal";
import CreditsModal from "../../../Modals/CreditsModal/CreditsModal";
import BackgroundModal from "./Background/Background";
import DimensionsModal from "./Dimensions/Dimensions";
import ErrorModal from "./Error/Error";
import Loader from "./Loader";
import RecordingModal from "./Recording/Recording";

const Modals = () => {
  const originalTemplateId = useSelector(state => state.ThreeSlice.present.originalTemplateId)
  return (
    <>
      <ErrorModal />
      <AttributionModal id={originalTemplateId} />
      <CreditsModal message="You are out of downloads. Upgrade your plan to download and gain access to all Previewed features." />
      <RecordingModal />
      <Loader />
    </>
  );
};

export const InnerModals = () => {
  return (
    <div style={{ position: "absolute" }}>
      <BackgroundModal />
      <DimensionsModal />
    </div>
  );
};

export default Modals;
