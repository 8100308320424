// extracted by mini-css-extract-plugin
export const container = "text-module--container--kCCvL";
export const section = "text-module--section--3eO17";
export const title = "text-module--title--16n65";
export const settings = "text-module--settings--1aHmH";
export const row = "text-module--row--2bhvB";
export const selectorButton = "text-module--selector-button--3ENTJ";
export const colorContainer = "text-module--color-container--2Zfje";
export const color = "text-module--color--2zSyS";
export const input = "text-module--input--2TSP7";
export const animationGrid = "text-module--animation-grid--3Osmj";