import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addText,
  removeClass,
  setClassParam,
} from "../../../../../../slices/three-slice";
import DropdownIcon from "../../../../../../assets/svg/dropdown.svg";
import Alignment from "../../../../../Common/Alignment/Alignment";
import ColorPicker from "../../../../../Common/ColorPicker/ColorPicker";
import NewFontPicker from "../../../../../Common/FontPicker/New";
import {
  degrees_to_radians,
  fetchFontFamily,
} from "../../../../../Common/Functions";
import GroupedSetting from "../../../../../Common/GroupedSetting/GroupedSetting";
import Select from "../../../../../Common/Select/Select";
import Slider from "../../../../../Common/Slider/Slider";
import AnimationSection from "../AnimationSection/AnimationSection";
import * as styles from "./text.module.scss";

import DeleteButton from "../../../../../Common/DeleteButton/DeleteButton";
import { connectSliders } from "../Functions";
import DraggableSetting from "../../../../../Common/DraggableSetting/DraggableSetting";
import CustomColorPicker from "../../../../../Common/CustomColorPicker/CustomColorPicker";
import { EditableInput } from "react-color/lib/components/common";
import tinycolor from "tinycolor2";

const TextTab = ({ id }) => {
  const dispatch = useDispatch();
  const currentText = useSelector(
    (state) => state.ThreeSlice.present.texts[id]
  );
  let { alignment, name, ratio, text, color, textAnimation } = currentText;

  let family = currentText.font?.family || currentText.fontFamily;
  let style = currentText.font?.style || currentText.fontWeight;

  const rgbColor = `rgb(${color[0] * 255}, ${color[1] * 255}, ${
    color[2] * 255
  })`;

  const scene = useSelector((state) => state.ThreeSlice.present.scene);
  const [fontWeights, setFontWeights] = useState([]);
  const [ref, setRef] = useState({});

  useEffect(() => {
    function updateRef() {
      let newRef = scene.getObjectByName(`texts-${id}`);
      if (newRef) {
        setRef(newRef);
      } else {
        setTimeout(() => updateRef(), 100);
      }
    }
    updateRef();
  }, [id]);

  let sliderData = useMemo(() => {
    return id !== undefined
      ? [
          {
            id: `texts-${id}-scale`,
            path: "scale.y",
          },
          {
            id: `texts-${id}-positionX`,
            path: "position.x",
          },
          {
            id: `texts-${id}-positionY`,
            path: "position.y",
          },
          {
            id: `texts-${id}-positionZ`,
            path: "position.z",
          },
          {
            id: `texts-${id}-rotation`,
            path: "material.rotation",
          },
          {
            id: `texts-${id}-opacity`,
            path: "material.opacity",
          },
        ]
      : [];
  }, [id]);

  connectSliders(sliderData, ref);


  useEffect(() => {
    (async () => {
      // Get weights
      if (currentText.font?.custom) {
        setFontWeights(["Custom"]);
      } else {
        let response = await fetch(
          "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDqZriFjXj28L5mKnwtU5DBGkmT6t25MuU"
        );
        let responseJson = await response.json();
        for (let i = 0; i < responseJson.items.length; i++) {
          if (family === responseJson.items[i].family) {
            setFontWeights(responseJson.items[i].variants);
            break;
          }
        }
      }
    })();
  }, []);

  function changeParam(param, value, axis) {
    if (param === "position") {
      value = { ...ref.position, [axis]: value };
    }
    dispatch(setClassParam({ param, value }));
  }

  function onChange(param, value, axis) {
    if (param === "position") {
      let newPosition = { ...ref.position, [axis]: value };
      let { x, y, z } = newPosition;
      ref.position.set(x, y, z);
    } else if (param === "scale") {
      ref.scale.set(ratio * value, value, 1);
    } else if (param === "opacity") {
      ref.material[param] = value;
    } else if (param === "rotation") {
      value = degrees_to_radians(value);
      ref.material[param] = value;
    }
  }

  function changeFont(family, styles, custom) {
    setFontWeights(styles);
    dispatch(
      setClassParam({
        param: "font",
        value: {
          family,
          style: styles[0],
          custom,
        },
      })
    );
  }

  function transformRGBA(rgba) {
    let colorsOnly = rgba
      .substring(rgba.indexOf("(") + 1, rgba.lastIndexOf(")"))
      .split(/,\s*/);
    return [colorsOnly[0] / 255, colorsOnly[1] / 255, colorsOnly[2] / 255];
  }
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <span className={styles.title}>Text</span>
        <div className={styles.settings}>
          <div className={styles.row}>
            <span>Content</span>
            <textarea
              key={id}
              className={styles.textarea}
              defaultValue={text}
              onChange={(e) =>
                dispatch(
                  setClassParam({ param: "text", value: e.target.value })
                )
              }
            />
          </div>
          <div className={styles.row}>
            <span>Font</span>
            <DraggableSetting
              position={{ x: 190, y: -28 }}
              parent={
                <div className={styles.selectorButton}>
                  <span>{family}</span>
                  <DropdownIcon />
                </div>
              }
              handle={"Font"}
            >
              <NewFontPicker currentFont={family} onChange={changeFont} />
            </DraggableSetting>
          </div>
          <div className={styles.row}>
            <span>Style</span>
            <Select
              options={fontWeights}
              value={style}
              onChange={(v) =>
                dispatch(
                  setClassParam({
                    param: "font",
                    value: {
                      family,
                      custom: currentText.font?.custom,
                      style: v,
                    },
                  })
                )
              }
              className={styles.fontStyle}
            />
          </div>
          <div className={styles.row}>
            <span>Size</span>
            <Slider
              id={`texts-${id}-scale`}
              min={0}
              max={1}
              step={0.01}
              onEnd={(value) => changeParam("scale", value)}
              onChange={(value) => onChange("scale", value)}
            />
          </div>
          <div className={styles.row}>
            <span>Align</span>
            <Alignment
              active={alignment}
              onClick={(value) =>
                dispatch(setClassParam({ param: "alignment", value }))
              }
            />
          </div>
          <div className={styles.row}>
            <span>Color</span>
            <div className={styles.colorContainer}>
              <DraggableSetting
                position={{ x: 190, y: -28 }}
                parent={
                  <div className={styles.color}>
                    <div style={{ backgroundColor: rgbColor }} />
                  </div>
                }
                handle={"Color"}
              >
                <CustomColorPicker
                  onColorChange={(color) =>
                    dispatch(
                      setClassParam({
                        param: "color",
                        value: transformRGBA(color),
                      })
                    )
                  }
                  color={rgbColor}
                />
              </DraggableSetting>
              <div className={styles.input}>
                <EditableInput
                  value={"#" + tinycolor(rgbColor).toHex()}
                  onChange={(color) =>
                    dispatch(
                      setClassParam({
                        param: "color",
                        value: transformRGBA(tinycolor(color).toRgbString()),
                      })
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <span>Rotation</span>
            <Slider
              id={`texts-${id}-rotation`}
              min={-360}
              max={360}
              step={0.01}
              onEnd={(value) => changeParam("rotation", value)}
              onChange={(value) => onChange("rotation", value)}
            />
          </div>
          <div className={styles.row}>
            <span>Opacity</span>
            <Slider
              id={`texts-${id}-opacity`}
              min={0}
              max={1}
              step={0.01}
              onEnd={(value) => changeParam("opacity", value)}
              onChange={(value) => onChange("opacity", value)}
            />
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <span className={styles.title}>Animation</span>
        <div className={styles.settings}>
          <AnimationSection textAnimation={textAnimation} />
        </div>
      </div>
      <div className={styles.section}>
        <span className={styles.title}>Position</span>
        <div className={styles.settings}>
          <div className={styles.row}>
            <span>X</span>
            <Slider
              id={`texts-${id}-positionX`}
              min={-10}
              max={10}
              step={0.001}
              onEnd={(value) => changeParam("position", value, "x")}
              onChange={(value) => onChange("position", value, "x")}
            />
          </div>
          <div className={styles.row}>
            <span>Y</span>
            <Slider
              id={`texts-${id}-positionY`}
              min={-10}
              max={10}
              step={0.001}
              onEnd={(value) => changeParam("position", value, "y")}
              onChange={(value) => onChange("position", value, "y")}
            />
          </div>
          <div className={styles.row}>
            <span>Z</span>
            <Slider
              id={`texts-${id}-positionZ`}
              min={-10}
              max={10}
              step={0.001}
              onEnd={(value) => changeParam("position", value, "z")}
              onChange={(value) => onChange("position", value, "z")}
            />
          </div>
        </div>
      </div>
      <DeleteButton
        onClick={() => dispatch(removeClass())}
        style={{ marginTop: 10 }}
      />
    </div>
  );
};

export default TextTab;
