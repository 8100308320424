import { useThree } from "@react-three/fiber";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { PerspectiveCamera } from '@react-three/drei'
import { objectAnimation } from "../Functions";

const Camera = (props) => {
  const ref = useRef();
  const cameraData = useSelector(state => state.ThreeSlice.present.camera)

  const { set, size } = useThree();


  objectAnimation(cameraData, ref.current)

  return (
    <PerspectiveCamera makeDefault zoom={cameraData.zoom} onUpdate={self => self.lookAt(0,0,0)} position={[cameraData.position.x, cameraData.position.y, cameraData.position.z]} aspect={size.width / size.height} name={'camera'} ref={ref} {...props} fov={20} near={1} far={1000} />
  );
};

export default Camera;
