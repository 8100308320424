
import React, { useRef, useMemo, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/3DModels/Phones/iphone_13/scene.gltf')

  const colorMaterials = useMemo(() => {
    const list = ['body_side_panel01','mat_blue','mat_blue_dark','body_side_panel_buttons01','body_side_panel_buttons_part_03','body_side_panel_buttons_part_02','chrome_back','back_camera_border01','Object10','Object13', 'mat_back', 'mat_back_02']

    return list.reduce((acc, name) => {
        let material =  nodes[name]?.material || materials[name]
        acc[name] = material.clone()
        return acc
    }, {})
  },[nodes, materials])

  const display = useMemo(() => materials.screen.clone(), [materials])

  useEffect(() => {
    if(group.current){
      props.init({ref: group.current, colorMaterials: Object.values(colorMaterials), display})
    }
  }, [])


  return (
    <group ref={group} {...props}>
     <group rotation={[0, -Math.PI, 0]} scale={[0.18, 0.18, 0.18]}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.body_side_panel01.geometry}
        material={colorMaterials.body_side_panel01}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.body_side_panel_part_02.geometry}
        material={colorMaterials.mat_blue}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.body_side_panel_bolts01.geometry}
        material={nodes.body_side_panel_bolts01.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.body_side_panel_dynamic01.geometry}
        material={nodes.body_side_panel_dynamic01.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.body_side_panel_part_01.geometry}
        material={materials.mat_black}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.body_side_panel_buttons_part_04.geometry}
        material={colorMaterials.mat_blue_dark}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.body_side_panel_buttons01.geometry}
        material={colorMaterials.body_side_panel_buttons01}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.body_side_panel_buttons_part_03.geometry}
        material={colorMaterials.body_side_panel_buttons_part_03}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.body_side_panel_buttons_part_02.geometry}
        material={colorMaterials.body_side_panel_buttons_part_02}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.body_side_panel_buttons_part_05.geometry}
        material={nodes.body_side_panel_buttons_part_05.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.usb01.geometry}
        material={materials.chrome}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.usb_part_01.geometry}
        material={materials.gold}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.front_glass02.geometry}
        material={nodes.front_glass02.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      /> */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.front_panel02.geometry}
        material={nodes.front_panel02.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.screen_border02.geometry}
        material={nodes.screen_border02.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.front_camera_border02.geometry}
        material={nodes.front_camera_border02.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.front_panel_dynamic_part_01.geometry}
        material={nodes.front_panel_dynamic_part_01.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.front_panel_dynamic_border02.geometry}
        material={nodes.front_panel_dynamic_border02.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane02.geometry}
        material={nodes.Plane02.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.front_panel_dynamic_part_03.geometry}
        material={nodes.front_panel_dynamic_part_03.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_panel_part_01.geometry}
        material={nodes.back_panel_part_01.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_flash_lens_part_01.geometry}
        material={materials.glossy_orange}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_flash_border_part_01.geometry}
        material={nodes.back_flash_border_part_01.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_flash_glass01.geometry}
        material={materials.glass_matte}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_flash_lens01.geometry}
        material={materials.glossy_yellow}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_glass01.geometry}
        material={materials.glass_matte_back}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_camera_panel01.geometry}
        material={colorMaterials.mat_back_02}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_flash_border01.geometry}
        material={nodes.back_flash_border01.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_camera_glass01.geometry}
        material={nodes.back_camera_glass01.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_panel01.geometry}
        material={colorMaterials.mat_back}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_glass_logo01.geometry}
        material={colorMaterials.chrome_back}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_camera_border01.geometry}
        material={colorMaterials.back_camera_border01}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_camera_border_part_01.geometry}
        material={nodes.back_camera_border_part_01.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_camera_glass_part_01.geometry}
        material={nodes.back_camera_glass_part_01.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_camera_border_part_02.geometry}
        material={nodes.back_camera_border_part_02.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_camera_border_part_03.geometry}
        material={nodes.back_camera_border_part_03.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_camera_lens_glass01.geometry}
        material={nodes.back_camera_lens_glass01.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object10.geometry}
        material={colorMaterials.Object10}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object13.geometry}
        material={colorMaterials.Object13}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object11.geometry}
        material={nodes.Object11.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object12.geometry}
        material={nodes.Object12.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object01.geometry}
        material={nodes.Object01.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object02.geometry}
        material={nodes.Object02.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object03.geometry}
        material={nodes.Object03.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object04.geometry}
        material={nodes.Object04.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object05.geometry}
        material={nodes.Object05.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object06.geometry}
        material={nodes.Object06.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.front_camera_lens02.geometry}
        material={nodes.front_camera_lens02.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object09.geometry}
        material={nodes.Object09.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_camera_lens01.geometry}
        material={nodes.back_camera_lens01.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object07.geometry}
        material={nodes.Object07.material}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.screen02.geometry}
        material={display}
        position={[0, -8.01, 0]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
    </group>
    </group>
  )
}

useGLTF.preload('/3DModels/Phones/iphone_13/scene.gltf')