import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeAnimation, changeTextAnimation, setClassParam } from "../../../../../../slices/three-slice";
import ColorPicker from "../../../../../Common/ColorPicker/ColorPicker";
import Select from "../../../../../Common/Select/Select";
import Animators from '../../../../../../assets/animations/texts/animators.json'
import * as styles from './animationsection.module.scss'
import cloneDeep from 'lodash/cloneDeep';
import Toggle from "react-toggle";



export default ({textAnimation}) => {
    let dispatch = useDispatch()
  function onChange(key, param, value){
    let newAnimation = cloneDeep(textAnimation)
    newAnimation[key][param] = value
    dispatch(setClassParam({param: 'textAnimation', value: newAnimation}))
  }
  return (
    <div className={styles.container}>
      <div>
          {['intro', 'outro'].map((item) => {
            return(<div className={styles.animation} key={item}>
                <span>{item}</span>
                <Select options={['None', ...Object.keys(Animators)]} value={textAnimation[item].type} onChange={(value) => onChange(item, 'type', value) } />
                {textAnimation[item].type !== 'None' ?
                <div>
                <Select options={['letters', 'words', 'lines']} value={textAnimation[item].by} onChange={(value) => onChange(item, 'by', value) } />
                <input type={'number'} value={textAnimation[item].duration} onChange={(e) => {
                  if(parseInt(e.target.value) > 0){
                    onChange(item, 'duration', e.target.value)}
                  }
                }/>
                <span>ms</span>
                </div> : null}
                </div>
            )
          })}
    </div>
    </div>
  );
};
