import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../../../../configureStore";
import { cloneItem, removeClass, deleteTimestamp } from "../../../../../slices/three-slice";
import Layer from "./Layer/Layer";
import * as styles from "./layers.module.scss";

const Layers = ({ list }) => {
  let dispatch = useDispatch();

  const timestamps = useMemo(
    () =>
      list.reduce((acc, item) => {
        if (Object.keys(item).length && item.class !== "camera") {
          let { start, end } = item.time;
          if (!acc.includes(start)) {
            acc.push(start);
          }
          if (!acc.includes(end)) {
            acc.push(end);
          }
        }
        return acc;
      }, []),
    [list]
  );

  useEffect(() => {
    let copiedData;
    let acceptedClasses = ["devices", "images", "texts"];
    let exclude = ["input", "textarea"];
    function onKeyDown(e) {

      if (exclude.indexOf(e.target.tagName.toLowerCase()) === -1) {
          // Clone Logic
        if (e.keyCode == 67 && (e.ctrlKey || e.metaKey)) {
          let timestamp = store.getState().ThreeSlice.present.currentTimestamp;
          if (timestamp.class) {
            copiedData = timestamp;
          } else {
            copiedData = store.getState().ThreeSlice.present.currentItem;
          }
        } else if (e.keyCode == 86 && (e.ctrlKey || e.metaKey)) {
          if (acceptedClasses.includes(copiedData.class)) {
            dispatch(cloneItem(copiedData));
          }
        }
        // Delete logic
        else if(e.keyCode == 8 || e.keyCode == 46){
          let timestamp = store.getState().ThreeSlice.present.currentTimestamp;
          if (timestamp.class) {
            // delete timestamp
            if(acceptedClasses.includes(timestamp.class)){
              dispatch(deleteTimestamp({...timestamp}))
            }
          } else {
            // delete whole item
            let itemToDelete = store.getState().ThreeSlice.present.currentItem;
            dispatch(removeClass({...itemToDelete}))
          }

        }
      }
    }
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <div className={styles.layers} id={"layers"}>
      <div className={styles.inner}>
        {list.map((item, index) => {
          if (item.class) {
            return <Layer item={item} timestamps={timestamps} key={index} />;
          }
        })}
      </div>
    </div>
  );
};

export default Layers;
