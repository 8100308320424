import React, { useEffect, useRef } from 'react'
import * as styles from './header.module.scss'
import File from './File/File'
import Menu from './3D-menu/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { setName, setRecording, setAnimated } from '../../../../slices/three-slice';
import Dropdown from '../../../Common/Dropdown/Dropdown';
import API from '../../../../firebase/api';
import { toast } from 'react-toastify';
import { store } from '../../../../../configureStore';
import { allowDownload } from '../../../Common/Functions';
import Download from '../../../Common/Download/Download';

const ThreeHeader = () => {
  const dispatch = useDispatch()
  const currentName = useSelector(state => state.ThreeSlice.present.name)
  const animated = useSelector(state => state.ThreeSlice.present.animated)
  const dimensions = useSelector(state => state.ThreeSlice.present.dimensions)
  let nameRef = useRef()


  useEffect(() => {
    // Update name
    if(nameRef.current && currentName !== nameRef.current.value){
        nameRef.current.value = currentName
    }
  }, [currentName])

  async function download(scale, free, format){
    dispatch(setRecording({param: 'info', value: {scale, free}}))
    dispatch(setRecording({param: 'format', value: format}))
    // let canDownload = await allowDownload()
    // if(canDownload){
    //   dispatch(setRecording({param: 'format', value: format}))
    // }
  }


      const downloadFormats = [
        {
          name: 'MP4 (fast)',
          value: 'mp4',
          disabled: !animated,
        },
        {
          name: 'WEBM (slow)',
          value: 'webm',
          disabled: !animated,
        },
        {
          name: 'PNG',
          value: 'png',
        }
      ]

      async function rename(name){
        if(name === currentName){
          return
        }
        let uuid = store.getState().ThreeSlice.present.uuid
        let saved = await API.doesTemplateExist(uuid)
        if(saved){
            let response = await API.editTemplate(uuid, {name})
            if(response){
                dispatch(setName(name))
                toast.success('✔ Renamed')
            }else {
                toast.error('Something went wrong...')
            }
        }else {
            dispatch(setName(name))
            toast.success('✔ Renamed')
        }
      }
    return(
        <div className={styles.container}>
            <div className={styles.left}>
                <File />
                <Menu />
            </div>
            <div className={styles.center}>
                <input ref={nameRef} onBlur={(e) => rename(e.target.value)} defaultValue={currentName} placeholder={'Untitled'}/>
            </div>
            <div className={styles.right}>
              <button onClick={() => dispatch(setAnimated(!animated))} className={styles.animate}>{animated ? 'Switch to image mode' : 'Switch to animation mode'}</button>
              <Download dimensions={dimensions} formats={downloadFormats} onDownload={download} paidOnly={true} />
              {/* <Dropdown parent={<button className={styles.download}>Download</button>} options={downloadOptions} style={{height: '100%'}} /> */}
              {/* <button onClick={() => dispatch(setRecording({param: 'status', value: true}))}>Record</button> */}
            </div>
            {/* <Dropdown parent={'Hello'} options={options}/> */}
        </div>

    )
}

export default ThreeHeader;