// The author of the original code is @mrdoob https://twitter.com/mrdoob
// https://threejs.org/examples/?q=con#webgl_shadow_contact

import { useFrame, useThree } from '@react-three/fiber'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import * as THREE from 'three'
import { HorizontalBlurShader, VerticalBlurShader } from 'three-stdlib'
import { store } from '../../../../../../configureStore'
import { onTimeUpdate } from '../Functions'

const ContactShadows = React.forwardRef(
  ({ opacity = 1, width = 1, height = 1, blur = 1, far = 10, resolution = 256, ...props }, ref) => {
    const {gl, scene} = useThree()
    
    const shadowCamera = React.useRef()
    // useHelper(shadowCamera, CameraHelper, 1, 'hotpink')
    const [
      renderTarget,
      planeGeometry,
      depthMaterial,
      blurPlane,
      horizontalBlurMaterial,
      verticalBlurMaterial,
      renderTargetBlur,
    ] = React.useMemo(() => {
      const renderTarget = new THREE.WebGLRenderTarget(resolution, resolution)
      const renderTargetBlur = new THREE.WebGLRenderTarget(resolution, resolution)
      renderTargetBlur.texture.generateMipmaps = renderTarget.texture.generateMipmaps = false
      const planeGeometry = new THREE.PlaneBufferGeometry(width, height).rotateX(Math.PI / 2)
      const blurPlane = new THREE.Mesh(planeGeometry)
      const depthMaterial = new THREE.MeshDepthMaterial()
      depthMaterial.depthTest = depthMaterial.depthWrite = false
      depthMaterial.onBeforeCompile = (shader) =>
        (shader.fragmentShader = shader.fragmentShader.replace(
          '1.0 - fragCoordZ ), opacity );',
          '0.0 ), ( 1.0 - fragCoordZ ) * 1.0 );'
        ))
      const horizontalBlurMaterial = new THREE.ShaderMaterial(HorizontalBlurShader)
      const verticalBlurMaterial = new THREE.ShaderMaterial(VerticalBlurShader)
      verticalBlurMaterial.depthTest = horizontalBlurMaterial.depthTest = false
      return [
        renderTarget,
        planeGeometry,
        depthMaterial,
        blurPlane,
        horizontalBlurMaterial,
        verticalBlurMaterial,
        renderTargetBlur,
      ]
    }, [resolution, width, height])

    function blurShadow(amount){
      blurPlane.material = horizontalBlurMaterial
      blurPlane.material.uniforms.tDiffuse.value = renderTarget.texture
      horizontalBlurMaterial.uniforms.h.value = amount / 256

      gl.setRenderTarget(renderTargetBlur)
      gl.render(blurPlane, shadowCamera.current)

      blurPlane.material = verticalBlurMaterial
      blurPlane.material.uniforms.tDiffuse.value = renderTargetBlur.texture
      verticalBlurMaterial.uniforms.v.value = amount / 256

      gl.setRenderTarget(renderTarget)
      gl.render(blurPlane, shadowCamera.current)
    }

    useFrame(() => {
      if (shadowCamera.current) {
        const initialBackground = scene.background
        const initialAlpha = gl.getClearAlpha()
        gl.setClearAlpha(0)
        scene.background = null
        scene.overrideMaterial = depthMaterial
        gl.setRenderTarget(renderTarget)
        gl.render(scene, shadowCamera.current)
        scene.overrideMaterial = null

        blurShadow(blur)
        blurShadow(blur * 0.4)

        gl.setRenderTarget(renderTarget)
        gl.render(blurPlane, shadowCamera.current)

        gl.setRenderTarget(null)
        scene.background = initialBackground
        gl.setClearAlpha(initialAlpha)
      }
    })

    return (
      <group {...props} ref={ref}>
        <mesh geometry={planeGeometry} scale={[1, -1, 1]} rotation={[-Math.PI / 2, 0, 0]}>
          <meshBasicMaterial depthWrite={false} map={renderTarget.texture} transparent opacity={opacity} />
        </mesh>
        <orthographicCamera ref={shadowCamera} args={[-width / 2, width / 2, height / 2, -height / 2, 0, far]} />
      </group>
    )
  }
)

const Shadows = () => {
    const active = useSelector(state => state.ThreeSlice.present.plane.active)
    const height = useSelector(state => state.ThreeSlice.present.plane.height)
    const blur = useSelector(state => state.ThreeSlice.present.plane.blur)
    const opacity = useSelector(state => state.ThreeSlice.present.plane.opacity)
    const time = useSelector(state => state.ThreeSlice.present.plane.time)

    let ref = useRef()

    function checkVisible(currentTime){
      if(active){
        if(currentTime >= time.start && currentTime <= time.end){
          // set visible
          ref.current.visible = true
        }
        else{
          // set invisible
          ref.current.visible = false
        }
      }
    }

    onTimeUpdate((currentTime) => {
      checkVisible(currentTime)
    })

    useEffect(() => {
      let currentTime = store.getState().ThreeSlice.present.currentTime
      checkVisible(currentTime)
    }, [time, active])


    return(
      active ?
        <ContactShadows 
        ref={ref}
        name={'plane'}
        rotation-x={Math.PI / 2}
        resolution={512}
        position={[0, height, 0]}
        opacity={opacity}
        width={20}
        height={20}
        blur={blur}
        far={100}/> : null
    )
}

export default Shadows;