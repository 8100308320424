import React from 'react'
import Dropdown from '../../../../../../Common/Dropdown/Dropdown';
import * as styles from './easing.module.scss'
// Icons
import LinearNoneSvg from '../../../../../../../assets/svg/transitions/linear-none.svg'
import CircularInSvg from '../../../../../../../assets/svg/transitions/circular-in.svg'
import CircularInOutSvg from '../../../../../../../assets/svg/transitions/circular-in-out.svg'
import CircularOutSvg from '../../../../../../../assets/svg/transitions/circular-out.svg'
import CubicInSvg from '../../../../../../../assets/svg/transitions/cubic-in.svg'
import CubicInOutSvg from '../../../../../../../assets/svg/transitions/cubic-in-out.svg'
import CubicOutSvg from '../../../../../../../assets/svg/transitions/cubic-out.svg'
import ElasticInSvg from '../../../../../../../assets/svg/transitions/elastic-in.svg'
import ElasticInOutSvg from '../../../../../../../assets/svg/transitions/elastic-in-out.svg'
import ElasticOutSvg from '../../../../../../../assets/svg/transitions/elastic-out.svg'
import QuadraticInSvg from '../../../../../../../assets/svg/transitions/quadratic-in.svg'
import QuadraticInOutSvg from '../../../../../../../assets/svg/transitions/quadratic-in-out.svg'
import QuadraticOutSvg from '../../../../../../../assets/svg/transitions/quadratic-out.svg'
import { useDispatch } from 'react-redux';
import { setEasing } from '../../../../../../../slices/three-slice';

const Easing = ({currentEase}) => {
    const dispatch = useDispatch()
    const options = [
        {
            name: 'Linear',
            icon: <LinearNoneSvg />,
            value: 'Linear.None',
        },
        {
            name: 'Quadratic In',
            icon: <QuadraticInSvg />,
            value: 'Quadratic.In',
        },
        {
            name: 'Quadratic Out',
            icon: <QuadraticOutSvg />,
            value: 'Quadratic.Out',
        },
        {
            name: 'Quadratic In & Out',
            icon: <QuadraticInOutSvg />,
            value: 'Quadratic.InOut',
        },
        {
            name: 'Circular In',
            icon: <CircularInSvg />,
            value: 'Circular.In',
        },
        {
            name: 'Circular Out',
            icon: <CircularOutSvg />,
            value: 'Circular.Out',
        },
        {
            name: 'Circular In & Out',
            icon: <CircularInOutSvg />,
            value: 'Circular.InOut',
        },

        {
            name: 'Cubic In',
            icon: <CubicInSvg />,
            value: 'Cubic.In',
        },
        {
            name: 'Cubic Out',
            icon: <CubicOutSvg />,
            value: 'Cubic.Out',
        },
        {
            name: 'Cubic In & Out',
            icon: <CubicInOutSvg />,
            value: 'Cubic.InOut',
        },

        {
            name: 'Elastic In',
            icon: <ElasticInSvg />,
            value: 'Elastic.In',
        },
        {
            name: 'Elastic Out',
            icon: <ElasticOutSvg />,
            value: 'Elastic.Out',
        },
        {
            name: 'Elastic In & Out',
            icon: <ElasticInOutSvg />,
            value: 'Elastic.InOut',
        },
    ]
    const reducedOptions = options.reduce((acc, item) => {
        acc.push({...item, onClick: () => dispatch(setEasing(item.value))})
        return acc
    }, [])
    
    const Parent = () => {
        let item = options.find(el => el.value === currentEase)
        return(
            <div>
                {item.icon}
            </div>
        )
    }
    return(
        <Dropdown options={reducedOptions} parent={<Parent />} dropdownClassName={styles.dropdown}/>
    )
}

export default Easing;