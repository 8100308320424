// extracted by mini-css-extract-plugin
export const container = "device-module--container--3X3lA";
export const section = "device-module--section--3MqgI";
export const settings = "device-module--settings--17iNa";
export const planeButton = "device-module--plane-button--2B3cX";
export const row = "device-module--row--1Ox22";
export const colorContainer = "device-module--color-container--3gIVh";
export const color = "device-module--color--z_Nch";
export const input = "device-module--input--31j7E";
export const title = "device-module--title--1UvVH";
export const grid = "device-module--grid--1-dEA";