
import { useGLTF } from '@react-three/drei'
import React, { useEffect, useMemo, useRef } from 'react'
import * as THREE from 'three'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/3DModels/Monitors/imac/scene.gltf')

  const colorMaterials = useMemo(() => {
    return ['Apple__Mac_24inch_05', 'Apple__Mac_24inch_06', 'Apple__Mac_24inch_03', 'Apple_Magic_Keyboard01', 'mouse_housing01'].reduce((acc, key) => {
        acc[key] = nodes[key].material.clone()
        return acc
    }, {})
  },[nodes])

  const display = useMemo(() => materials.screen.clone(), [materials]);

  useEffect(() => {
    if (group.current) {
        // Change some things
        nodes.Apple_Magic_Keyboard_04.material.color = new THREE.Color(0.1,0.1,0.1)
        materials.glossy_white.color = new THREE.Color(0.4,0.4,0.4)
        // Initialise
      props.init({
        ref: group.current,
        colorMaterials: Object.values(colorMaterials),
        display,
      });
    }
  }, []);

  return (
    <group ref={group} {...props}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_01.geometry}
        material={nodes.Apple__Mac_24inch_01.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_03.geometry}
        material={colorMaterials.Apple__Mac_24inch_03}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_04.geometry}
        material={nodes.Apple__Mac_24inch_04.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_05.geometry}
        material={colorMaterials.Apple__Mac_24inch_05}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_06.geometry}
        material={colorMaterials.Apple__Mac_24inch_06}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_07.geometry}
        material={nodes.Apple__Mac_24inch_07.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_08.geometry}
        material={nodes.Apple__Mac_24inch_08.material}
        position={[0, -2.32725, 0]}
      />
      {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_09.geometry}
        material={nodes.Apple__Mac_24inch_09.material}
        position={[0, -2.32725, 0]}
      /> */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_10.geometry}
        material={nodes.Apple__Mac_24inch_10.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_11.geometry}
        material={materials.text_black}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_12.geometry}
        material={nodes.Apple__Mac_24inch_12.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_13.geometry}
        material={nodes.Apple__Mac_24inch_13.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_14.geometry}
        material={nodes.Apple__Mac_24inch_14.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_15.geometry}
        material={nodes.Apple__Mac_24inch_15.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_17.geometry}
        material={nodes.Apple__Mac_24inch_17.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_18.geometry}
        material={nodes.Apple__Mac_24inch_18.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_19.geometry}
        material={nodes.Apple__Mac_24inch_19.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_20.geometry}
        material={nodes.Apple__Mac_24inch_20.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_21.geometry}
        material={nodes.Apple__Mac_24inch_21.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_22.geometry}
        material={nodes.Apple__Mac_24inch_22.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_24.geometry}
        material={nodes.Apple__Mac_24inch_24.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_25.geometry}
        material={nodes.Apple__Mac_24inch_25.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_26.geometry}
        material={nodes.Apple__Mac_24inch_26.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_27.geometry}
        material={nodes.Apple__Mac_24inch_27.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_28.geometry}
        material={materials.mat_gray_dark}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_29.geometry}
        material={nodes.Apple__Mac_24inch_29.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard01.geometry}
        material={colorMaterials.Apple_Magic_Keyboard01}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_01.geometry}
        material={nodes.Apple_Magic_Keyboard_01.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_02.geometry}
        material={nodes.Apple_Magic_Keyboard_02.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_04.geometry}
        material={nodes.Apple_Magic_Keyboard_04.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_10.geometry}
        material={nodes.Apple_Magic_Keyboard_10.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_13.geometry}
        material={nodes.Apple_Magic_Keyboard_13.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_11.geometry}
        material={nodes.Apple_Magic_Keyboard_11.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_03.geometry}
        material={nodes.Apple_Magic_Keyboard_03.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_05.geometry}
        material={nodes.Apple_Magic_Keyboard_05.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_17.geometry}
        material={nodes.Apple_Magic_Keyboard_17.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_07.geometry}
        material={nodes.Apple_Magic_Keyboard_07.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_06.geometry}
        material={nodes.Apple_Magic_Keyboard_06.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_08.geometry}
        material={nodes.Apple_Magic_Keyboard_08.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_15.geometry}
        material={nodes.Apple_Magic_Keyboard_15.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_12.geometry}
        material={nodes.Apple_Magic_Keyboard_12.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_14.geometry}
        material={nodes.Apple_Magic_Keyboard_14.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_leg01.geometry}
        material={nodes.mouse_leg01.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_02.geometry}
        material={materials.glossy_white}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_detail06.geometry}
        material={nodes.mouse_detail06.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_detail02.geometry}
        material={nodes.mouse_detail02.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_detail07.geometry}
        material={nodes.mouse_detail07.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_detail08.geometry}
        material={nodes.mouse_detail08.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_glass02.geometry}
        material={nodes.mouse_glass02.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_back_plane01.geometry}
        material={nodes.mouse_back_plane01.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_detail04.geometry}
        material={materials.bronze}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_detail01.geometry}
        material={nodes.mouse_detail01.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_detail05.geometry}
        material={nodes.mouse_detail05.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_housing01.geometry}
        material={colorMaterials.mouse_housing01}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_back_plane03.geometry}
        material={nodes.mouse_back_plane03.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_text03.geometry}
        material={nodes.mouse_text03.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_text01.geometry}
        material={nodes.mouse_text01.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_03.geometry}
        material={nodes.mouse_03.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object03.geometry}
        material={nodes.Object03.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_23.geometry}
        material={nodes.Apple__Mac_24inch_23.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object01.geometry}
        material={nodes.Object01.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_16.geometry}
        material={display}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple__Mac_24inch_02.geometry}
        material={nodes.Apple__Mac_24inch_02.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Apple_Magic_Keyboard_16.geometry}
        material={nodes.Apple_Magic_Keyboard_16.material}
        position={[0, -2.32725, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse_button01.geometry}
        material={nodes.mouse_button01.material}
        position={[0, -2.32725, 0]}
      />
    </group>
  )
}

useGLTF.preload('/3DModels/Monitors/imac/scene.gltf')